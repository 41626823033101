<div style="padding: 20px;">
  <div class="table-container mat-elevation-z4">
    <div *ngIf="isLoadingResults" fxLayout="row" fxLayoutAlign="center center" class="loading-shade">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>
    <table mat-table [dataSource]="dataSource">
      <!-- Image -->
      <ng-container matColumnDef="imageB64">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
            <img mat-card-image style="width:50px; height: auto; border-radius: 50%; margin: 0;" src="{{row.imageB64}}" alt="...">
        </td>
      </ng-container>

      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let row">{{row._id}}</td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let row">{{row.createdAt | date:'dd/MM/yyyy HH:mm'}}</td>
      </ng-container>

      <!-- Detection Score Column -->
      <ng-container matColumnDef="detectionScore">
        <th mat-header-cell *matHeaderCellDef>Pontuação da detecção</th>
        <td mat-cell *matCellDef="let row">{{row.alignedRect._score | number:'1.3-3'}}</td>
      </ng-container>

      <!-- Gender Column -->
      <ng-container matColumnDef="gender">
        <th mat-header-cell *matHeaderCellDef>Gênero</th>
        <td mat-cell *matCellDef="let row">{{row.gender}}</td>
      </ng-container>

      <!-- Age Column -->
      <ng-container matColumnDef="age">
        <th mat-header-cell *matHeaderCellDef>Idade</th>
        <td mat-cell *matCellDef="let row">{{row.age}}</td>
      </ng-container>

      <!-- bestExpression Column -->
      <ng-container matColumnDef="bestExpression">
        <th mat-header-cell *matHeaderCellDef>Expressão</th>
        <td mat-cell *matCellDef="let row">{{row.bestExpression?.best}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['detail', row._id]"></tr>
    </table>
  </div>
</div>
