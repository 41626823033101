import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { Customer } from '../models/customer.model';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { DashBoardFilterService } from './dashboard-filter.service';
import { CustomerDetection } from '../models/customerDetection.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  baseUrl = environment.restApi + '/customers/search';
  chartUrl = environment.restApi + '/customers/';
  processUrl = environment.processApi;

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private filterService: DashBoardFilterService) { }

  showMessage(msg: string): void {
    this.snackBar.open(msg, 'X', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  create(customer: Customer): Observable<Customer> {
    return this.http.post<Customer>(this.baseUrl, customer);
  }

  sendDetection(detection: CustomerDetection): Observable<CustomerDetection> {
    return this.http.post<CustomerDetection>(`${this.processUrl}/customer`, detection);
  }

  notifyProcess(): Observable<any> {
    return this.http.post<any>(`${this.processUrl}/customer`, {});
  }

  update(customer: Customer, id: number): Observable<Customer> {
    return this.http.put<Customer>(this.baseUrl + '/' + id, customer);
  }

  get(data: any): Observable<Customer[]> {
    return this.http.get<Customer[]>(this.baseUrl, {
      params: data
    });
  }

  getById(id: string): Observable<Customer> {
    return this.http.get<Customer>(this.baseUrl + '/' + id);
  }

  getDetail(id: string): Observable<Customer[]> {
    return this.http.get<Customer[]>(this.baseUrl + '/detail/' + id);
  }

  patchProcess() {
    return this.http.patch(this.processUrl + '/customers/process/', {});
  }

  patchProcess_in_out() {
    return this.http.patch(this.processUrl + '/customers/process/in_out/', {});
  }

  getMidTime() {
    const { initialDate, finalDate } = this.filterService.dashBoardFilter;
    const requestDateFilter = initialDate && finalDate ? `?startDate=${initialDate}T00:01&endDate=${finalDate}T23:59` : '';
    return this.http.get<any[]>(`${this.chartUrl}middleTimeGraph${requestDateFilter}`);
  }

  getMidTime2() {
    const currentDate = moment().format('YYYY-MM-DD');
    return this.http.get<any[]>(`${this.chartUrl}middleTimeGraph2?startDate=${currentDate}T00:01&endDate=${currentDate}T23:59`);
  }

  getExpressionChartData() {
    const { initialDate, finalDate } = this.filterService.dashBoardFilter;
    const requestDateFilter = initialDate && finalDate ? `?startDate=${initialDate}T00:01&endDate=${finalDate}T23:59` : '';
    return this.http.get<any[]>(`${this.chartUrl}analytics/expression${requestDateFilter}`);
  }

  getGenderChartData() {
    const { initialDate, finalDate } = this.filterService.dashBoardFilter;
    const requestDateFilter = initialDate && finalDate ? `?startDate=${initialDate}T00:01&endDate=${finalDate}T23:59` : '';
    return this.http.get<any[]>(`${this.chartUrl}analytics/gender${requestDateFilter}`);
  }

  getGenderChartData2() {
    const currentDate = moment().format('YYYY-MM-DD');
    return this.http.get<any[]>(`${this.chartUrl}analytics/gender2?startDate=${currentDate}T00:01&endDate=${currentDate}T23:59`);
  }

  getNumberOfPeoples() {
    const { initialDate, finalDate } = this.filterService.dashBoardFilter;
    const requestDateFilter = initialDate && finalDate ? `?startDate=${initialDate}T00:01&endDate=${finalDate}T23:59` : '';
    return this.http.get<any[]>(`${this.chartUrl}numberOfPeoples${requestDateFilter}`);
  }

  getCustomersInStore() {
    return this.http.get<Customer[]>(`${environment.restApi}/customers/inStore`);
  }
}
