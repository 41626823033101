<!-- /home -->
<div class="store-select">
  <span class="store-select-title">Você está visualizando informações da Loja:</span>
  <div class="store-select-fields">
    <mat-form-field appearance="outline" class="store-select">
      <mat-label>Loja selecionada</mat-label>
      <mat-select [value]="selectedStore" (selectionChange)="changeStore($event.value)">
        <mat-option [value]="store" *ngFor="let store of stores">{{
          store.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <button (click)="openDashboardFilterDialog()" style="margin-left: -20px;" *ngIf="showFilter && this.toolbar.toolbarSelect.bottonToolbar"
      mat-icon-button color="primary">
      <mat-icon class="material-icons-two-tone blue">filter_alt</mat-icon>
    </button>

    <img style="margin-left: 20px;" *ngIf="showFilterHeatmap && this.toolbar.toolbarSelect.bottonToolbar"
      (click)="openFilterDialog()" src="../../assets/heatmap-icons/filter_alt-24px.svg" alt="icon" />
  </div>
</div>

<div class="nav-menu" fxLayoutAlign="center center">
  <div>
    <nav class="mat-tab-links" mat-tab-nav-bar disablePagination="true" [class.center]="!showFilter"
      [class.end]="showFilter">
      <a class="monitoring-a" *ngIf="this.toolbar.toolbarSelect.topToolbar" mat-tab-link [routerLink]="'/monitoring'"
        routerLinkActive #rlb="routerLinkActive" [active]="rlb.isActive">
        MONITORAMENTO
      </a>
      <a *ngIf="this.toolbar.toolbarSelect.topToolbar" mat-tab-link [routerLink]="'/heatmap'" routerLinkActive
        #rlh="routerLinkActive" [active]="rlh.isActive">
        HEATMAP
      </a>
      <a *ngIf="this.toolbar.toolbarSelect.topToolbar" mat-tab-link [routerLink]="'/dashboard'" routerLinkActive
        #rla="routerLinkActive" [active]="rla.isActive">
        DASHBOARD
      </a>
      <div *ngIf="this.toolbar.toolbarSelect.topToolbar">
        <button (click)="openDashboardFilterDialog()" *ngIf="showFilter && this.toolbar.toolbarSelect.topToolbar" mat-icon-button color="primary">
          <mat-icon class="material-icons-two-tone blue">filter_alt</mat-icon>
        </button>
        <button (click)="clearFilters()" *ngIf="anyFilterIsSelected()" mat-icon-button>
          <mat-icon class="material-icons">close</mat-icon>
        </button>
      </div>
      <img *ngIf="showFilterHeatmap && this.toolbar.toolbarSelect.topToolbar" class="size-img"
        (click)="openFilterDialog()" src="../../assets/heatmap-icons/filter_alt-24px.svg" alt="icon" />

      <div class="filter-btn" *ngIf="showFilterHeatmap">
        <img #externalClose class="external-close" style="width: 24px; height: 24px; margin: 0 20px; display: none;"
          src="../../assets/heatmap-icons/external-close.svg" alt="" />

        <div #filtroButton class="selected-filter">
          <img style="width: 20px; height: 22px; margin-right: 10px;"
            src="../../assets/heatmap-icons/calendar_today-white-24dp.svg" alt="" />
          <p>{{ formatDate(initialDate) }}</p>
          <span style="color: #03adfc;"> - </span>
          <p>{{ formatDate(finalDate) }}</p>
          <img style="width: 24px; height: 24px; margin-left: 10px;" src="../../assets/heatmap-icons/Symbol 64 – 2.svg"
            (click)="filterClean()" alt="" />
        </div>
      </div>
    </nav>
  </div>
  <div fxLayout="column" fxFlex="35" fxLayoutAlign="center" *ngIf="showFilter" class="mat-chip-lists">
    <mat-chip-list>
      <mat-chip 
        class="filter-chip"
        *ngIf="filterService.dashBoardFilter.initialDate !== '' && filterService.dashBoardFilter.finalDate !== ''"
        (removed)="removeDate()">
        <mat-icon class="material-icons-two-tone blue" matPrefix>calendar_today</mat-icon>
        {{ filterService.dashBoardFilter.initialDate }} - {{ filterService.dashBoardFilter.finalDate }}
        <mat-icon matChipRemove>close</mat-icon>
      </mat-chip>
      <mat-chip *ngIf="filterService.dashBoardFilter.gender !== 'all'" class="filter-chip" (removed)="removeGender()">
        {{ filterService.dashBoardFilter.gender | translate }}
      <mat-icon matChipRemove>close</mat-icon>
      </mat-chip>
      <ng-container *ngFor="let range of filterService.dashBoardFilter.ageRange | keyvalue">
        <mat-chip class="filter-chip" *ngIf="range.value === true" (removed)="removeRange(range)">
          {{ range.key }}
          <mat-icon matChipRemove>close</mat-icon>
        </mat-chip>
      </ng-container>
      <ng-container *ngFor="let expression of filterService.dashBoardFilter.expressions | keyvalue">
        <mat-chip class="filter-chip" *ngIf="expression.value === true" (removed)="removeExpression(expression)">
          {{ expression.key | translate }}
          <mat-icon matChipRemove>close</mat-icon>
        </mat-chip>
      </ng-container>
    </mat-chip-list>
  </div>
  <div class="filter-btn-device" style="display: none;" *ngIf="showFilterHeatmap">
    <img #externalClose2 class="external-close" hidden style="width: 24px; height: 24px; margin: 0 20px; display: none;"
      src="../../assets/heatmap-icons/external-close.svg" (click)="filterClean()" alt="" />

    <div #filtroButton2 class="selected-filter">
      <img style="width: 20px; height: 22px; margin-right: 10px;"
        src="../../assets/heatmap-icons/calendar_today-white-24dp.svg" alt="" />
      <p>{{ formatDate(initialDate) }}</p>
      <span style="color: #03adfc;"> - </span>
      <p>{{ formatDate(finalDate) }}</p>
      <img style="width: 24px; height: 24px; margin-left: 10px;" src="../../assets/heatmap-icons/Symbol 64 – 2.svg"
        (click)="filterClean()" alt="" />
    </div>
  </div>
  <div *ngIf="this.toolbar.page === 'monitoring' || this.toolbar.page === 'heatmap'" class="space" >

  </div>
</div>

<div class="container" fxLayout="column" style="max-height: 100%;">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>

<mat-toolbar id="toolbarNavId" class="toolbarNav" style="position: fixed; height: 75px;" fxLayoutAlign="center center"
  *ngIf="this.toolbar.page !== 'monitoring'">
  <div fxLayout="row" fxLayoutGap="60px">
    <div>
      <button class="heatmap" type="submit" (click)="selectPage('heatmap','')" fxLayout="column"
        fxLayoutAlign="center center" [routerLink]="'/heatmap'">
        <mat-icon class="material-icons">
          analytics
        </mat-icon>
        Heatmap
      </button>
    </div>
    <div>
      <button class="dashboard" (click)="selectPage('dashboard','')" fxLayout="column" fxLayoutAlign="center center"
        [routerLink]="'/dashboard'">
        <mat-icon class="material-icons">
          donut_small
        </mat-icon>
        Dashboard
      </button>
    </div>
  </div>
</mat-toolbar>
