import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { Store } from '../shared/models/store.model';
import { Observable } from 'rxjs';
import { StoreService } from '../shared/services/store.service';
import { startWith, map } from 'rxjs/operators';
import { Camera } from '../shared/models/camera.model';

@Component({
  selector: 'app-cam-assoc',
  templateUrl: './cam-assoc.component.html',
  styleUrls: ['./cam-assoc.component.scss']
})
export class CamAssocComponent implements OnInit {

  form: FormGroup;
  storesCtrl = new FormControl();
  stores: Store[] = [];
  filteredStores: Observable<Store[]>;
  isLoading: boolean;
  webcams: InputDeviceInfo[];
  devicesAccessIsBlocked: boolean;

  constructor(
    private storeService: StoreService,
    private fb: FormBuilder) {}

  async ngOnInit() {
    this.buildForm();
    this.webcams = await this.getWebcams();
    this.getStoreList();
  }

  get cameras() {
    return this.form.get('cameras') as FormArray;
  }

  loadSelectedStore(store: Store): void {
    this.storesCtrl.setValue(store.name);
    this.form.patchValue(store);
    this.cameras.clear();
    this.buildCameras(store.cameras);
  }

  submitForm(): void {
    this.storeService.update(this.form.value, this.form.value._id).subscribe(res => {
      if (res._id === this.storeService.selectedStore._id) {
        this.storeService.selectedStore = { ...res };
      }
      this.storeService.showMessage('Câmera associada com sucesso!');
    });
  }

  // PRIVATE METHODS

  private getWebcams() {
    try {
      return navigator.mediaDevices.enumerateDevices()
        .then((devices: InputDeviceInfo[]) => {
            return devices.filter((device) => {
                return device.kind === 'videoinput';
            });
        });
    } catch (error) {
      this.devicesAccessIsBlocked = true;
      this.isLoading = false;
    }
  }

  private getStoreList(): void {
    this.storeService.get().subscribe((stores: Store[]) => {
      this.stores = stores;
      this.filteredStores = this.storesCtrl.valueChanges
      .pipe(
        startWith(''),
        map(store => store ? this._filterStores(store) : this.stores.slice())
      );
    });
  }

  private buildForm() {
    this.form = this.fb.group({
      _id: [''],
      name: [''],
      cameras: this.fb.array([])
    });
  }

  private buildCameras(cameras: Camera[]) {
    cameras.forEach((camera => {
      this.cameras.push(this.fb.group({
        deviceId: [camera.deviceId],
        type: [camera.type],
        description: [camera.description]
      }));
    }));
  }

  private _filterStores(value): Store[] {
    const filterValue = value.name?.toLowerCase() || value.toLowerCase();

    return this.stores.filter(store => store.name.toLowerCase().indexOf(filterValue) === 0);
  }

}
