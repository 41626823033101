<div class="main-container">
  <h3 class="mat-subheading-2"><strong>Filtragem</strong></h3>
  <form class="form" [formGroup]="form" (ngSubmit)="filter()" fxLayout="column">
    <div mat-dialog-content fxLayout="row wrap" fxLayoutGap="69px">
      <div class="data" fxLayout="column">
        <mat-form-field color="primary" appearance="outline">
          <mat-label>Data Inicial</mat-label>
          <input formControlName="initialDate" matInput [matDatepicker]="pickerInitialDate">
          <mat-datepicker-toggle matSuffix [for]="pickerInitialDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerInitialDate color="primary"></mat-datepicker>
        </mat-form-field>

        <mat-form-field color="primary" appearance="outline">
          <mat-label>Data Final</mat-label>
          <input formControlName="finalDate" matInput [matDatepicker]="pickerFinalDate">
          <mat-datepicker-toggle matSuffix [for]="pickerFinalDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerFinalDate color="primary"></mat-datepicker>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="gender" color="primary" appearance="outline">
          <mat-label>Sexo</mat-label>
          <mat-select formControlName="gender">
            <mat-option value="all">Ambos</mat-option>
            <mat-option value="male">Masculino</mat-option>
            <mat-option value="female">Feminino</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="container">
        <strong>Humor:</strong>
        <div class="expressions" formGroupName="expressions" fxLayout="row" fxLayoutGap="28px">
          <div fxLayout="column" fxLayoutGap="25px">
            <mat-checkbox color="primary" class="mat-small" formControlName="neutral">Neutro</mat-checkbox>
            <mat-checkbox color="primary" class="mat-small" formControlName="happy">Feliz</mat-checkbox>
            <mat-checkbox color="primary" class="mat-small" formControlName="sad">Triste</mat-checkbox>
            <mat-checkbox color="primary" class="mat-small" formControlName="surprised">Surpreso</mat-checkbox>
          </div>
          <div fxLayout="column" fxLayoutGap="25px">
            <mat-checkbox color="primary" class="mat-small" formControlName="angry">Raiva</mat-checkbox>
            <mat-checkbox color="primary" class="mat-small" formControlName="fearful">Receio</mat-checkbox>
            <mat-checkbox color="primary" class="mat-small" formControlName="disgusted">Nojo</mat-checkbox>
          </div>
        </div>
        <strong>Idade:</strong>
        <div class="age" formGroupName="ageRange" fxLayout="row" fxLayoutGap="28px">
          <div fxLayout="column" fxLayoutGap="25px">
            <mat-checkbox color="primary" class="mat-small" formControlName="0-10">0-10</mat-checkbox>
            <mat-checkbox color="primary" class="mat-small" formControlName="10-20">10-20</mat-checkbox>
            <mat-checkbox color="primary" class="mat-small" formControlName="20-30">20-30</mat-checkbox>
          </div>
          <div fxLayout="column" fxLayoutGap="25px">
            <mat-checkbox color="primary" class="mat-small" formControlName="30-40">30-40</mat-checkbox>
            <mat-checkbox color="primary" class="mat-small" formControlName="40-50">40-50</mat-checkbox>
            <mat-checkbox color="primary" class="mat-small" formControlName="50-60">50-60</mat-checkbox>
          </div>
        </div>
      </div>

    </div>
    <div mat-dialog-actions fxLayoutAlign="end">
      <button type="submit" mat-stroked-button color="primary">Filtrar</button>
    </div>
  </form>
</div>