<div>
  <div style="width: 100%; height: auto;">
    <div class="content-all">
      <div *ngFor="let imageChartItem of imageChart">
        <div class="content-graph">
          <!--image 1-->
          <mat-card *ngIf="!(imageChartItem.images === '');else noData" class="graph-img">
            <div class="label">{{ imageChartItem.spaceName }}</div>
            <img [src]="this.imageChartItem.images" alt="image1">
          </mat-card>

          <ng-template #noData>
            <mat-card class="graph-img">
              <div class="label">{{ imageChartItem.spaceName }}</div>
              <div class="not-image">
                <p>Nenhuma imagem disponível</p>
              </div>
            </mat-card>
          </ng-template>
          <!--chart 1-->

          <mat-card class="chart">
              <canvas baseChart style="height: 120%;"
                [datasets]="imageChartItem.lineChartData"
                [labels]="imageChartItem.lineChartLabels"
                [options]="barChartOptions"
                [legend]="barChartLegend"
                [chartType]="barChartType"
                [colors]="lineChartColors">
              </canvas>

          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="spinner">
  <div style="display: inline-block;" class="spinner" *ngIf="isLoading">
    <mat-progress-spinner [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
  </div>
</div>
