import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  hide = true;
  form: FormGroup;
  navigateTo: string;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.navigateTo = this.activatedRoute.snapshot.params['to'];
  }

  createForm() {
    this.form = this.fb.group({
      user: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  // submitForm() {
  //   this.loginService.login(this.form.value.user, this.form.value.password)
  //     .subscribe(
  //       user => console.log(user),
  //       error => console.error('Error:', error),
  //       () => this.router.navigate([this.navigateTo]));
  // }

  submitForm() {
    this.loginService.login();
    this.router.navigate([this.navigateTo]);
  }
}
