<div *ngIf="isLoadingResults" fxLayout="row" fxLayoutAlign="center center" class="loading-shade">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>

<div fxLayout="column" fxLayoutGap="24px">
  <app-webcam-warning-card *ngIf="devicesAccessIsBlocked"></app-webcam-warning-card>

  <div class="moviment-container" fxLayout="row" fxLayoutGap="24px">

    <mat-card class="mat-elevation-z4" fxFlex fxLayout="column">
      <div class="mat-title" fxLayout="row" fxFLex fxLayoutAlign="space-between center">
        <p mat-card-title>Movimentação</p>
        <p class="mat-title-data">{{ this.currentDate }}</p>
      </div>

      <div fxLayout="row">

        <div fxLayout="column" fxFlex="70" fxLayoutAlign="end start" class="moviment-quantity-container">
          <p style="font-size: 72px;">{{ this.allMovement }}</p>
        </div>

        <div fxFlex class="moviment-table">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>0-18 anos</p>
            <p>{{ genderData[0] ? genderData[0].gender[2].total : 0}}</p>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>18-35 anos</p>
            <p>{{ genderData[1] ? genderData[1].gender[2].total : 0}}</p>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>35-50 anos</p>
            <p>{{ genderData[2] ? genderData[2].gender[2].total : 0}}</p>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>50+ anos</p>
            <p>{{ genderData[3] ? genderData[3].gender[2].total : 0}}</p>
          </div>
        </div>

      </div>
    </mat-card>

    <mat-card class="mat-elevation-z4" fxFlex fxLayout="column">
      <div class="mat-title" fxLayout="row" fxFLex fxLayoutAlign="space-between center">
        <p mat-card-title>Homem</p>
        <p class="mat-title-data">{{ this.currentDate }}</p>
      </div>

      <div fxLayout="row">

        <div fxLayout="column" fxFlex="70" fxLayoutAlign="end start" class="moviment-quantity-container">
          <p style="font-size: 72px;"> {{ this.allMaleMovement }} </p>
        </div>

        <div fxFlex class="moviment-table">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>0-18 anos</p>
            <p>{{ genderData[0] ? genderData[0].gender[0].male : 0}}</p>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>18-35 anos</p>
            <p>{{ genderData[1] ? genderData[1].gender[0].male : 0}}</p>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>35-50 anos</p>
            <p>{{ genderData[2] ? genderData[2].gender[0].male : 0}}</p>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>50+ anos</p>
            <p>{{ genderData[3] ? genderData[3].gender[0].male : 0}}</p>
          </div>
        </div>

      </div>
    </mat-card>

    <mat-card class="mat-elevation-z4" fxFlex fxLayout="column">
      <div class="mat-title" fxLayout="row" fxFLex fxLayoutAlign="space-between center">
        <p mat-card-title>Mulher</p>
        <p class="mat-title-data">{{ this.currentDate }}</p>
      </div>

      <div fxLayout="row">

        <div fxLayout="column" fxFlex="70" fxLayoutAlign="end start" class="moviment-quantity-container">
          <p style="font-size: 72px;">{{ this.allFemaleMovement }}</p>
        </div>

        <div fxFlex class="moviment-table">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>0-18 anos</p>
            <p>{{ genderData[0] ? genderData[0].gender[1].female : 0 }}</p>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>18-35 anos</p>
            <p>{{ genderData[1] ? genderData[1].gender[1].female : 0 }}</p>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>35-50 anos</p>
            <p>{{ genderData[2] ? genderData[2].gender[1].female : 0}}</p>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>50+ anos</p>
            <p>{{ genderData[3] ? genderData[3].gender[1].female : 0}}</p>
          </div>
        </div>

      </div>
    </mat-card>

    <mat-card class="mat-elevation-z4" fxFlex fxLayout="column">
      <div class="mat-title no-margin" fxLayout="row" fxFLex fxLayoutAlign="space-between center">
        <p mat-card-title>Tempo de Permanência</p>
        <p class="mat-title-data">{{ this.currentDate }}</p>
      </div>

      <div fxLayout="row">

        <div fxLayout="column" fxFlex="70" fxLayoutAlign="end start" class="moviment-quantity-container">
          <p style="font-size: 72px;">{{ this.allPermanence | number: "1.0-0" }}</p>
        </div>

        <div fxFlex class="moviment-table">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>0-18 anos</p>
            <p>{{ midTimeData[0] ? midTimeData[0].total : 0 }}</p>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>18-35 anos</p>
            <p>{{ midTimeData[1] ? midTimeData[1].total : 0 }}</p>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>35-50 anos</p>
            <p>{{ midTimeData[2] ? midTimeData[2].total : 0 }}</p>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>50+ anos</p>
            <p>{{ midTimeData[3] ? midTimeData[3].total : 0 }}</p>
          </div>
        </div>

      </div>
    </mat-card>

  </div>

  <div>
    <mat-card *ngIf="currentCustomers.length > 0; else noCustomersCard" fxLayout="column" class="mat-elevation-z4 current-customers-card">
      <p mat-card-title class="mat-title">Clientes na Loja<span>{{this.currentCustomers.length}}</span></p>
      <div>
        <div fxLayout="row">
          <div fxLayout="column" fxLayoutAlign="center center">
            <button [class.invisible]="!showLeftArrow()" mat-icon-button (click)="scroll('left')">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
          </div>
          <div #currentCustomersContainer class="current-customers-container" fxLayout="row nowrap">
            <div class="current-customer" *ngFor="let customer of customers; index as i" (click)="showCustomerDetail(customer._id)">
              <ng-container *ngIf="currentCustomers[i]; else elseBlock">
                <div class="current-customer-image" [style.backgroundImage]="'url('+ currentCustomers[i].imageB64 +')'"></div>
                <ul>
                  <li>{{currentCustomers[i].momentInformation.length}} {{currentCustomers[i].momentInformation.length > 1 ? 'visitas' : 'visita'}}</li>
                  <li>{{currentCustomers[i].gender | translate}}</li>
                </ul>
              </ng-container>

              <ng-template #elseBlock>
                <div class="current-customer-image ghost-customer"></div>
                <ul class="ghost-customer-list">
                  <li>
                    <div class="ghost-customer-visits"></div>
                  </li>
                  <li>
                    <div class="ghost-customer-gender"></div>
                  </li>
                </ul>
              </ng-template>
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center">
            <button [class.invisible]="!showRightArrow()" mat-icon-button (click)="scroll('right')">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-card>

    <ng-template #noCustomersCard>
      <mat-card class="no-customers-card" fxLayout fxLayoutAlign="center center">
        <p>Não há clientes na Loja.</p>
      </mat-card>
    </ng-template>
  </div>


  <div fxLayout="row" fxLayoutGap="24px" style="max-height: 50vh;">
    <mat-card class="mat-elevation-z4" fxFlex *ngFor="let cam of registeredCameras">
      <!-- <div style="max-height: 100%;"> -->
      <!-- VIDEO STREAM -->
      <div [id]="cam.deviceId" fxFlex style="overflow: hidden; position: relative;">
        <!-- CAM DESCRIPTION -->
        <div class="cam-label"><span>{{cam.description}}</span></div>
        <!--  -->
        <!-- AQUI VEM O VIDEO!!! -->
      </div>
      <!-- FECHA VIDEO STREAM -->
      <!-- </div> -->
    </mat-card>
  </div>

</div>
