import { Heatmap } from './../models/heatmap.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeatmapFilterService {
  private _filterSource = new BehaviorSubject<any>({
    initialDate: new Date(new Date().setHours(0 , 0 , 0)),
    finalDate: new Date(new Date().setHours(23, 59, 59)),
    peripheralCompanyId: 1
  });

  private _currentFilter = this._filterSource.asObservable();

  constructor() { }

  get heatmapFilter() {
    return this._filterSource.value;
  }

  set heatmapFilter(filter) {
    this.setHeatmapFilter(filter);
    this._filterSource.next(filter);
  }

  get currentFilter() {
    return this._currentFilter;
  }

  // PRIVATE METHODS

  private setHeatmapFilter(filter) {
    this._filterSource.value.peripheralCompanyId = 1;
    this._filterSource.value.initialDate = filter.initialDate;
    this._filterSource.value.finalDate = filter.finalDate;
  }
}
