import { HeatmapFilterService } from './../shared/services/heatmap-filter.service';
import { Observable, Subscription } from 'rxjs';
import { HeatmapComponent } from './../heatmap/heatmap.component';
import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { StoreService } from '../shared/services/store.service';
import { Store } from '../shared/models/store.model';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { MatDialog } from '@angular/material/dialog';
import { DashboardFilterDialogComponent } from '../dashboard-filter-dialog/dashboard-filter-dialog.component';
import { FilterDateDialogComponent } from '../filter-date-dialog/filter-date-dialog.component';
import { DashBoardFilterService } from '../shared/services/dashboard-filter.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResizeService } from './../shared/services/resize.service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public toolbar: any = {
    dimensions: {
      width:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.getElementsByTagName('body')[0].clientWidth,
      height:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.getElementsByTagName('body')[0].clientHeight,
    },
    page: window.location.pathname.toString().split('/')[1],
    toolbarSelect: {
      topToolbar: false,
      bottonToolbar: true,
    },
  };
  public stores: Store[];
  public selectedStore: Store;
  public isLoadingResults: boolean;
  public showFilter: boolean;
  public showFilterHeatmap: boolean;
  public initialDate: Date;
  public finalDate: Date;
  public myClass;
  public myOverlay;
  public myId;
  public monitoringTabClass;
  setToolbar: Subscription;

  @ViewChild('filtroButton') filtroButton: ElementRef;
  @ViewChild('externalClose') externalClose: ElementRef;
  @ViewChild('filtroButton2') filtroButton2: ElementRef;
  @ViewChild('externalClose2') externalClose2: ElementRef;

  constructor(
    private storeService: StoreService,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private heatmapFilterService: HeatmapFilterService,
    public filterService: DashBoardFilterService,
    private snackBar: MatSnackBar,
    private resizeService: ResizeService
  ) { }

  ngOnInit() {
    this.resizeService.listenElements();
    this.setToolbar = this.resizeService.listenEvent.subscribe(
      (toolbarSelect: any) => {
        this.toolbar = toolbarSelect;
        this.selectPage(this.toolbar.page, this.toolbar.dimensions);
      }
    );

    this.isLoadingResults = true;

    this.storeService.get().subscribe((stores) => {
      this.isLoadingResults = false;
      this.stores = stores;
      if (this.stores.length) { this.selectedStore = this.stores[0]; }
    });
    this.resize();
  }

  public selectPage(page, dimensions) {
    console.log(dimensions);

    if (page === 'dashboard' && dimensions.width <= 768) {
      let tab: any = document.getElementsByClassName('dashboard')[0];
      let notTab: any = document.getElementsByClassName('heatmap')[0];
      tab.style.color = '#03adfc';
      notTab.style.color = '#7a7a7a';
    } else if (page === 'heatmap' && dimensions.width <= 768) {
      let tab: any = document.getElementsByClassName('heatmap')[0];
      let notTab: any = document.getElementsByClassName('dashboard')[0];
      tab.style.color = '#03adfc';
      notTab.style.color = '#7a7a7a';
    }
  }

  public resize() {
    setInterval(() => {
      let path = window.location.pathname;
      const resolutionChange = window.matchMedia('(max-width: 1024px)');
      // if (resolutionChange.matches && path === '/monitoring') {
      //   window.location.href = '/dashboard';
      // }
    }, 250);
  }

  public changeStore(store: Store) {
    this.storeService.selectedStore = { ...store };
  }

  public onActivate(componentRef): void {
    componentRef instanceof DashboardComponent
      ? (this.showFilter = true)
      : (this.showFilter = false);
    componentRef instanceof HeatmapComponent
      ? (this.showFilterHeatmap = true)
      : (this.showFilterHeatmap = false);
  }

  public openDashboardFilterDialog(): void {
    this.dialog.open(DashboardFilterDialogComponent);
    this.myClass = document.getElementsByClassName('cdk-overlay-pane');
    this.myClass[0].id = 'my-id';
    this.myOverlay = document.getElementsByClassName('cdk-overlay-container');
    this.myOverlay[0].id = 'my-overlay-container';
  }

  openFilterDialog(): Observable<any> {
    const dialogRef = this.dialog.open(FilterDateDialogComponent, {
      data: {
        initialDate: '',
        finalDate: '',
      },
      width: '550px',
    });

    const idDialog: any = document.getElementsByClassName(
      'mat-dialog-container'
    )[0];
    idDialog.style = {};
    console.log(idDialog);
    dialogRef.afterClosed().subscribe((res) => {
      this.initialDate = res.initialDate;
      this.finalDate = res.finalDate;

      this.renderer.setStyle(
        this.filtroButton.nativeElement,
        'display',
        'flex'
      );
      this.renderer.setStyle(
        this.externalClose.nativeElement,
        'display',
        'inline-block'
      );
      this.renderer.setStyle(
        this.filtroButton2.nativeElement,
        'display',
        'flex'
      );
      this.renderer.setStyle(
        this.externalClose2.nativeElement,
        'display',
        'inline-block'
      );
    });

    return dialogRef.afterClosed();
  }

  formatDate(date: Date): string {
    const formattedDate = new Date(date);
    return (
      formattedDate.getDate() +
      '/' +
      (formattedDate.getMonth() + 1) +
      '/' +
      formattedDate.getFullYear()
    );
  }

  filterClean() {
    this.renderer.setStyle(this.filtroButton.nativeElement, 'display', 'none');
    this.renderer.setStyle(this.externalClose.nativeElement, 'display', 'none');
    this.renderer.setStyle(this.filtroButton2.nativeElement, 'display', 'none');
    this.renderer.setStyle(
      this.externalClose2.nativeElement,
      'display',
      'none'
    );

    const data = {
      initialDate: '',
      finalDate: '',
    };

    this.heatmapFilterService.heatmapFilter = data;
  }

  removeGender() {
    this.filterService.dashBoardFilter.gender = 'all'
    this.filterService.dashBoardFilter = this.filterService.dashBoardFilter;
  }

  removeRange(range) { 
    this.filterService.dashBoardFilter.ageRange[range.key] = false;
    this.filterService.dashBoardFilter = this.filterService.dashBoardFilter;
  }

  removeExpression(expression) {
    this.filterService.dashBoardFilter.expressions[expression.key] = false;
    this.filterService.dashBoardFilter = this.filterService.dashBoardFilter;
  }

  removeDate() {
    this.filterService.dashBoardFilter.initialDate = '';
    this.filterService.dashBoardFilter.finalDate = '';
    this.filterService.dashBoardFilter = this.filterService.dashBoardFilter;
  }

  anyFilterIsSelected() {
    const selectedQuantity = Object.values({
      ...this.filterService.dashBoardFilter.expressions,
      ...this.filterService.dashBoardFilter.ageRange,
    }).filter((value) => value === true).length;
    return selectedQuantity > 0;
  }

  clearFilters() {
    this.filterService.resetFilter();
  }

  // PRIVATE METHOS
  private showSnackBar(message) {
    this.snackBar.open(message, 'X', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  ngOnDestroy() {
    this.setToolbar.unsubscribe();
  }
}
