
// tslint:disable-next-line: no-shadowed-variable
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { StoreService } from '../shared/services/store.service';
import { Subscription } from 'rxjs';
import { Store } from '../shared/models/store.model';
import * as faceapi from 'face-api.js';
import { SocketioService } from './socketio.service';
import { Router } from '@angular/router';
import { CustomerService } from '../shared/services/customer.service';
import * as moment from 'moment';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent implements OnInit, OnDestroy {
  @ViewChild('currentCustomersContainer') container: any;
  private storeSubscription: Subscription;
  private wsSubscription: Subscription;
  public webcams: InputDeviceInfo[];
  public registeredCameras: any[];
  public store: Store;
  public streams: MediaStream[] = [];
  public intervals: any[] = [];
  public devicesAccessIsBlocked: boolean;
  public isLoadingResults: boolean;
  public leaveComponent: boolean;
  public currentCustomers: any[] = [];
  private imgBuffers = {};
  private camerasNotifyFlags = {};
  public genderData: any[] = [];
  public midTimeData: any[] = [];
  public allMovement;
  public allMaleMovement;
  public allFemaleMovement;
  public allPermanence;
  public currentDate;
  public customers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  constructor(
    private storeService: StoreService,
    private socketService: SocketioService,
    private router: Router,
    private customerService: CustomerService) {}

  ngOnInit() {
    this.currentDate = moment().format('DD/MM/YYYY');
    this.isLoadingResults = true;
    this.loadCustomersInStore();
    this.subscribeToWebSocket();
    this.getGenderData();
    this.getMidTime();
    setTimeout(() => {
      this.storeSubscription = this.storeService.currentSelectedStore.subscribe(resp => {
        if (resp._id !== undefined) { this.load(); }
      });
    }, 1000);
  }

  ngOnDestroy(): void {
    this.storeSubscription.unsubscribe();
    this.wsSubscription.unsubscribe();
    this.leaveComponent = true;
    this.stopStream();
  }

  public startStream() {
    this.isLoadingResults = false;
    this.registeredCameras.forEach(camera => {
      this.camerasNotifyFlags[camera.deviceId] = false;
      this.imgBuffers[camera.deviceId] = '';

      const cardContent = document.getElementById(camera.deviceId);
      const video: any = document.createElement('video');
      video.style.maxWidth = '100%';
      video.style.width = '100%';
      video.style.height = '100%';
      video.style.objectFit = 'fill';
      video.setAttribute('autoplay', 'true');
      video.setAttribute('muted', 'true');
      video.setAttribute('playsinline', 'true');
      const device = this.webcams.find(webcam => webcam.deviceId === camera.deviceId);
      video.camera = this.store.cameras.find(storeCamera => storeCamera.deviceId === camera.deviceId);
      video.store = this.store;
      const constraints: MediaStreamConstraints = {
        audio: false,
        video: { deviceId: device.deviceId }
      };
      navigator.mediaDevices.getUserMedia(constraints)
        .then((stream: MediaStream) => {
          video.srcObject = stream;
          this.streams.push(stream);
          video.setAttribute('class', 'video-class');
          video.addEventListener('play', async () => {
            const canvas = faceapi.createCanvasFromMedia(video);
            canvas.style.position = 'absolute';
            canvas.setAttribute('class', 'video-canvas');
            canvas.style.maxWidth = '100%';
            canvas.style.width = '100%';
            canvas.style.height = '100%';
            canvas.style.objectFit = 'fill';

            const canvasSize = {
              width: video.videoWidth,
              height: video.videoHeight
            };
            cardContent.appendChild(canvas);
            cardContent.appendChild(video);

            this.onPlay(video, canvas, canvasSize, camera);
          });
        });
    });
  }

  public stopStream() {
    this.clearStreams();
    this.clearTimeouts();
    this.removeElement('video');
    this.removeElement('.video-canvas');
  }

  public isOverflow() {
    if (this.container) {
      const el = this.container.nativeElement;
      return el.scrollWidth > el.clientWidth;
    }
    return false;
  }

  public scroll(direction) {
    const el = this.container.nativeElement;
    if(direction === 'right') {
      el.scrollLeft += 250;
    } else {
      el.scrollLeft -= 250;
    }
  }

  public showLeftArrow(): boolean {
    if (this.container) {
      const el = this.container.nativeElement;
      return this.isOverflow() && el.scrollLeft !== 0;
    }
    return false;
  }

  public showRightArrow(): boolean {
    if (this.container) {
      const el = this.container.nativeElement;
      return this.isOverflow() && el.offsetWidth + el.scrollLeft !== el.scrollWidth;
    }
    return false;
  }

  // PRIVATE METHODS

  private loadCustomersInStore() {
    this.customerService.getCustomersInStore().subscribe(resp => this.currentCustomers = resp);
  }

  private subscribeToWebSocket() {
    this.wsSubscription = this.socketService.customersFromSocketIo()
      .subscribe(
        resp => {
          this.currentCustomers = resp;
          this.getGenderData();
          this.getMidTime();
        },
        err => console.log(err)
    );
  }

  private async load() {
    this.stopStream();
    this.isLoadingResults = true;
    this.webcams = await this.getWebcams();
    this.store = await this.storeService.selectedStore;
    this.registeredCameras = this.getRegisteredCameras();

    await this.loadModels();

    setTimeout(() => {
      this.startStream();
    }, 1000);
  }

  private getWebcams() {
    try {
      return navigator.mediaDevices.enumerateDevices()
        .then((devices: InputDeviceInfo[]) => {
          return devices.filter((device) => {
            return device.kind === 'videoinput';
          });
        });
    } catch (error) {
      this.devicesAccessIsBlocked = true;
      this.isLoadingResults = false;
    }
  }

  private getRegisteredCameras() {
    return this.store.cameras.filter(camera => this.webcams.some(webcam => webcam.deviceId === camera.deviceId));
  }

  private loadModels() {
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri('../assets/face-api-models'),
      faceapi.nets.faceRecognitionNet.loadFromUri('../assets/face-api-models')
    ]).then();
  }

  private async getCroppedImage(x, y, w, h, url) {
    return new Promise(resolve => {
      const cv = document.createElement('canvas');
      const ctx = cv.getContext('2d');
      const img = new Image();

      img.onload = async () => {
        cv.width = w;
        cv.height = h;
        // ctx.drawImage(img, x, y, w, h, 0, 0, w, h);
        ctx.drawImage(img, x - 30, y - 30, w + 60, h + 60, 0, 0, w, h);
        resolve(cv.toDataURL('image/jpeg', 0.8));
      };
      img.src = url;
    });
  }

  private videoSnapshotBase64(video) {
    const cv = document.createElement('canvas');
    const ctx = cv.getContext('2d');

    cv.width = video.videoWidth;
    cv.height = video.videoHeight;

    ctx.drawImage(video, 0, 0);

    const image64 = cv.toDataURL('image/jpeg', 0.8);
    return new Promise((resolve) => resolve(image64));
  }

  private videoSnapshotImageElement(video) {
    return new Promise(resolve => {
      const cv = document.createElement('canvas');
      const ctx = cv.getContext('2d');
      const img = new Image();

      cv.width = video.videoWidth;
      cv.height = video.videoHeight;

      img.onload = async () => {
        resolve(img);
      };

      ctx.drawImage(video, 0, 0);
      img.src = cv.toDataURL('image/jpeg', 0.8);
    });
  }

  private clearStreams() {
    this.streams.forEach(stream => {
      stream.getTracks().forEach(track => track.stop());
    });
  }

  private clearTimeouts() {
    this.intervals.forEach(interval => clearTimeout(interval));
  }

  private removeElement(elementName: string) {
    const elements = document.querySelectorAll(elementName);
    elements.forEach(el => el.parentNode.removeChild(el));
  }

  private describe(detection, camera) {
    camera.gender = detection.gender === 'female' ? 'Feminino' : 'Masculino';
    camera.age = Math.round(detection.age);

    for (const expression in detection.expressions) {
      if (detection.expressions[expression] > 0.75) {
        switch (expression) {
          case 'angry':
            camera.bestExpression = 'Raiva';
            break;
          case 'disgusted':
            camera.bestExpression = 'Enojado';
            break;
          case 'fearful':
            camera.bestExpression = 'Medo';
            break;
          case 'happy':
            camera.bestExpression = 'Feliz';
            break;
          case 'neutral':
            camera.bestExpression = 'Neutro';
            break;
          case 'sad':
            camera.bestExpression = 'Triste';
            break;
          case 'surprised':
            camera.bestExpression = 'Surpreso';
            break;
        }
      }
    }
  }

  private async onPlay(video, canvas, canvasSize, camera) {
    let imgBuff = this.imgBuffers[video.camera.deviceId];
    imgBuff = await this.videoSnapshotBase64(video);
    const detections = await faceapi
      .detectAllFaces(video, this.getFaceDetectorOptions());

    if (detections.length > 0) {
      const dims = faceapi.matchDimensions(canvas, video, true);
      const resizedDetections = faceapi.resizeResults(detections, dims);
      this.camerasNotifyFlags[camera.deviceId] = true;

      faceapi.draw.drawDetections(canvas, resizedDetections);

      detections.forEach(async (detection: any, i) => {
        const {x, y, width, height} = detection.box;
        // const b64 = await this.getCroppedImage(x, y, width, height, imgBuff);
        const b64 = await this.getCroppedImage(x - 25, y - 25, width + 50, height + 50, imgBuff);
        detection.imageB64 = b64;
        detection.video = video;

        this.customerService.sendDetection(this.setDetection(detection)).subscribe(
          (res: any) => {
            console.log(res.message);
            if (res.detection) { this.describe(res.detection, camera); }
          }, err => console.log
        );
      });
    }
    else {
      canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
      if (this.camerasNotifyFlags[camera.deviceId]) {
        this.customerService.notifyProcess().subscribe(
          res => console.log(res.message),
          err => console.log(err)
        );
        this.camerasNotifyFlags[camera.deviceId] = false;
      }
    }
    if (!this.leaveComponent) {
      this.onPlay(video, canvas, canvasSize, camera);
    }
  }

  public showCustomerDetail(id) {
    this.router.navigate(['customers/detail', id]);
  }

  private getFaceDetectorOptions() {
    const inputSize = 512;
    const scoreThreshold = 0.7;
    return new faceapi.TinyFaceDetectorOptions({ scoreThreshold, inputSize });
  }

  private setDetection(detection) {
    const { deviceId, description, type } = detection.video.camera;

    const camera = { deviceId, description, type };

    return {
      timestamp: Math.round(new Date().getTime() / 1000),
      imageB64: detection.imageB64,
      camera,
      store: { id: detection.video.store._id, name: detection.video.store.name }
    };
  }

  private getGenderData() {

    this.customerService.getGenderChartData2()
      .subscribe(res => { 
        this.allMovement = 0;
        this.allMaleMovement = 0;
        this.allFemaleMovement = 0;
        this.genderData = res;

        for(let data of this.genderData) {
          this.allMovement += data.gender[2].total, 10;
          this.allMaleMovement += data.gender[0].male;
          this.allFemaleMovement += data.gender[1].female;
        }
      });
  }

  private getMidTime() {
    this.customerService.getMidTime2()
    .subscribe(res => {
        this.allPermanence = 0;
        this.midTimeData = res.map(item => {
          return {
            range: item.AgeRange,
            total: item.values.male + item.values.female
          }
        });

        this.allPermanence = this.midTimeData.reduce((acc, cur) => acc +  cur.total, 0);
      });
  }

}
