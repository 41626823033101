<!-- HEADER -->
<header>
  <mat-toolbar fxLayout="row" class="flex space-between" color="primary">
    <div fxFlex>
      <a routerLink=""><span>Customer Profile e Heatmap</span></a>
    </div>

    <div fxFlex fxLayoutAlign="end center">
      <button mat-icon-button aria-label="Example icon-button with a menu" routerLink="/customers">
        <mat-icon class="material-icons-outlined face">face</mat-icon>
      </button>

      <button mat-icon-button (click)="openMenuDialog()" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon class="material-icons-outlined settings">settings</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/stores">
          <mat-icon color="primary">store</mat-icon>
          <span>Lojas</span>
        </button>
        <button mat-menu-item routerLink="/cam-assoc">
          <mat-icon color="primary">device_hub</mat-icon>
          <span>Associar câmeras</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>
</header>
<!--  -->
