import { HeatmapFilterService } from './../shared/services/heatmap-filter.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogDate {
  initialDate: Date;
  finalDate: Date;
}

@Component({
  selector: 'app-filter-date-dialog',
  templateUrl: './filter-date-dialog.component.html',
  styleUrls: ['./filter-date-dialog.component.css']
})
export class FilterDateDialogComponent implements OnInit {

  form: FormGroup;

  initialDate: Date;
  finalDate: Date;

  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 50;
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<FilterDateDialogComponent>,
    private heatmapFilterService: HeatmapFilterService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDate
  ) { }

  ngOnInit(): void {
    this.formBuild();
  }

  formBuild() {
    this.form = this.formBuilder.group({
      initialDate: [],
      finalDate: []
    });
  }

  dateSelectedInitial(event) {
    const date = event.value;
    this.initialDate = new Date(date.getFullYear() + '/'
      + (date.getMonth() + 1) + '/'
      + date.getDate() + ' '
      + '00:00:00');
  }

  dateSelectedFinal(event) {
    const date = event.value;
    this.finalDate = new Date(date.getFullYear() + '/'
      + (date.getMonth() + 1) + '/'
      + date.getDate() + ' '
      + '23:59:59');
  }

  onNoClick(): void {
    this.dialogRef.close(this.data);
  }

  save() {
    this.data.initialDate = this.initialDate;
    this.data.finalDate = this.finalDate;

    this.heatmapFilterService.heatmapFilter = this.data;
    this.dialogRef.close(this.data);
  }


}
