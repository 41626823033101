import { LoginComponent } from './login/login.component';
import { HeatmapComponent } from './heatmap/heatmap.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StoreListComponent } from './store/store-list/store-list.component';
import { StoreFormComponent } from './store/store-form/store-form.component';
import { CamAssocComponent } from './cam-assoc/cam-assoc.component';
import { CustomerDetailComponent } from './customer/customer-detail/customer-detail.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { LoggedInGuard } from './shared/services/loggedin.guard';

const routes: Routes = [
  {path: '', component: HomeComponent,
    children: [
      {path: '', redirectTo: 'monitoring', pathMatch: 'full', canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
      {path: 'monitoring', component: MonitoringComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
      {path: 'heatmap', component: HeatmapComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
      {path: 'dashboard', component: DashboardComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
    ]
  },
  {path: 'login', component: LoginComponent},
  {path: 'login/:to', component: LoginComponent},
  {path: 'stores', component: StoreListComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
  {path: 'stores/edit/:id', component: StoreFormComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
  {path: 'cam-assoc', component: CamAssocComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
  {path: 'customers', component: CustomerListComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]},
  {path: 'customers/detail/:id', component: CustomerDetailComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
