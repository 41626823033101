import { Component, OnInit, ViewChild, AfterViewInit, AfterContentChecked } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { StoreService } from 'src/app/shared/services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Camera } from 'src/app/shared/models/camera.model';

@Component({
  selector: 'app-store-form',
  templateUrl: './store-form.component.html',
  styleUrls: ['./store-form.component.scss']
})
export class StoreFormComponent implements OnInit, AfterViewInit, AfterContentChecked {
  @ViewChild ('btnAddCam')
  btnAddCam: MatButton;

  @ViewChild ('btnSubmit')
  btnSubmit: MatButton;

  types = ['ENTRADA', 'SAÍDA', 'N/A'];
  currentAction: string;
  form: FormGroup;
  pageTitle: string;
  serverErrorMessages: string[] = null;
  submittingForm = false;
  isLoading: boolean;

  constructor(

    private storeService: StoreService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.setCurrentAction();
    this.buildForm();
    this.loadResources();
  }

  ngAfterViewInit(): void {
    this.changeSubmitButton();
  }

  ngAfterContentChecked(): void {
    this.setPageTitle();
  }

  get cameras() {
    return this.form.get('cameras') as FormArray;
  }

  addCamera(deviceId = '', type = '', description = '') {
    this.cameras.push(
      this.fb.group({
        deviceId: [deviceId],
        type: [type, Validators.required],
        description: [description, Validators.required]
      })
    );
  }

  removeCamera(i: number) {
    this.cameras.removeAt(i);
  }

  submitForm() {
    if (this.currentAction === 'new') {
      this.save();
    } else if (this.currentAction === 'edit'){
      this.update();
    } else {
      this.delete();
    }
  }

  save() {
    const store = this.form.value;
    this.storeService.create(store).subscribe(res => {
      this.storeService.showMessage('Loja Salva com sucesso!');
      this.router.navigate(['/stores']);
    }, err => {
      console.log(err);
    });
  }

  update() {
    const store = this.form.value;
    this.storeService.update(store, store._id).subscribe(res => {
      if (res._id === this.storeService.selectedStore._id) {
        this.storeService.selectedStore = { ...res };
      }
      this.storeService.showMessage('Loja Atualizada com sucesso!');
      this.router.navigate(['/stores']);
    }, err => {
      console.log(err);
    });
  }

  delete() {
    const id = this.route.snapshot.params.id;
    this.storeService.delete(id).subscribe(res => {
      this.storeService.showMessage('Loja deletada com sucesso!');
      this.router.navigate(['/stores']);
    });
  }

  // PRIVATE METHODS
  private setCurrentAction() {
    const actionPath = this.route.snapshot.url[1].path;

    if (actionPath === 'edit') {
      this.currentAction = 'edit';
    } else if (actionPath === 'delete') {
      this.currentAction = 'delete';
    } else {
      this.currentAction = 'new';
    }
  }

  private buildForm() {
    this.form = this.fb.group({
      _id: [''],
      name: ['', Validators.required],
      cameras: this.fb.array([])
    });
  }

  private loadResources() {
    if (this.currentAction !== 'new') {
      this.isLoading = true;
      const id = this.route.snapshot.params.id;
      this.storeService.getById(id).subscribe(res => {
        this.form.patchValue(res);
        this.buildCameras(res.cameras);
        this.isLoading = false;
      });
    }
  }

  private buildCameras(cameras: Camera[]) {
    cameras.forEach((camera => {
      this.addCamera(camera.deviceId, camera.type, camera.description);
    }));

    this.deactivateForm();
  }

  private deactivateForm() {
    if (this.currentAction === 'delete') {
      this.form.disable();
      this.btnAddCam.disabled = true;
    }
  }

  private changeSubmitButton() {
    if (this.currentAction === 'delete') {
      this.btnSubmit.color = 'warn';
      this.btnSubmit._elementRef.nativeElement.textContent = 'Excluir';
    }
  }

  private setPageTitle() {
    if (this.currentAction === 'new') {
      this.pageTitle = 'Nova Loja';
    } else if (this.currentAction === 'edit') {
      this.pageTitle = 'Alterar Loja';
    } else {
      this.pageTitle = 'Excluir Loja';
    }
  }
}
