import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatChipsModule } from '@angular/material/chips';

import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WebcamWarningCardComponent } from './shared/components/webcam-warning-card/webcam-warning-card.component';
import { ChartsModule } from 'ng2-charts';
import { StoreListComponent } from './store/store-list/store-list.component';
import { StoreFormComponent } from './store/store-form/store-form.component';
import { CamAssocComponent } from './cam-assoc/cam-assoc.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { CustomerDetailComponent } from './customer/customer-detail/customer-detail.component';
import { DashboardFilterDialogComponent } from './dashboard-filter-dialog/dashboard-filter-dialog.component';
import { HeatmapComponent } from './heatmap/heatmap.component';
import { FilterDateDialogComponent } from './filter-date-dialog/filter-date-dialog.component';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { LoginComponent } from './login/login.component';
import { LoginService } from './login/login.service';
import { LoggedInGuard } from './shared/services/loggedin.guard';

import { ResizeService } from './shared/services/resize.service.service'
import { AuthInterceptor } from './shared/services/auth.interceptor';
import { AllowWebcamAccessDialogComponent } from './shared/components/allow-webcam-access-dialog/allow-webcam-access-dialog.component';

import { MAT_DATE_LOCALE} from '@angular/material/core';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    MonitoringComponent,
    DashboardComponent,
    WebcamWarningCardComponent,
    StoreListComponent,
    StoreFormComponent,
    CamAssocComponent,
    CustomerListComponent,
    CustomerDetailComponent,
    DashboardFilterDialogComponent,
    AllowWebcamAccessDialogComponent,
    HeatmapComponent,
    FilterDateDialogComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatCheckboxModule,
    ChartsModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    LoginService,
    LoggedInGuard,
    ResizeService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
