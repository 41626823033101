<div [formGroup]="form" class="content-fields" id="content-fields-id">
    <h4 mat-dialog-title>Filtragem</h4>
    <div  class="space-fields">
      <mat-form-field appearance="outline" >
        <mat-label>Data Inicial</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="initialDate" (dateChange)="dateSelectedInitial($event)"  [(ngModel)]="data.initialDate">
        <mat-datepicker-toggle matSuffix [for]="picker" class="btn-style" ></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker></mat-datepicker>
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label>Data Final</mat-label>
        <input matInput  [matDatepicker]="picker2" formControlName="finalDate" (dateChange)="dateSelectedFinal($event)" [(ngModel)]="data.finalDate">
        <mat-datepicker-toggle matSuffix [for]="picker2" class="btn-style"></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker2 ></mat-datepicker>
      </mat-form-field>
    </div>
  
    <div mat-dialog-actions >
      <button (click)="save()" mat-stroked-button class="btn-style" >Filtrar</button>
    </div>
  
    <!-- <div style="text-align: center; margin-top: 50px;">
      <div style="display: inline-block;" class="spinner" *ngIf="isLoading">
        <mat-progress-spinner [color]="color" [mode]="mode" [value]="value">
        </mat-progress-spinner>
      </div>
    </div> -->
  
  </div>
  