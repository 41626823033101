<div class="content gradient">
  <div class="item-logo">
    <div class="content-logo">
      <img class="logo" alt="logo" src="./assets/img/logo-customer-profile.png">
    </div>
  </div>
  <div class="item-card">
    <div>
      <mat-card class="item-mat-card">
        <mat-card-header>
          <mat-card-title class="title">Bem-vindo</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="form" (ngSubmit)="submitForm()" class="example-form">

            <div class="items-buttons">

              <mat-form-field class="size-fields" appearance="outline">
                <mat-label>Usuário</mat-label>
                <input formControlName="user" matInput placeholder="Usuário">
              </mat-form-field>

              <mat-form-field class="size-fields" appearance="outline">
                <mat-label>Senha</mat-label>
                <input formControlName="password" matInput [type]="hide ? 'password' : 'text'" placeholder="Senha">
              </mat-form-field>

              <button mat-flat-button color="primary" class="size-buttons">ENTRAR</button>

            </div>
          </form>

          <a>
            <p class="forgot-password">ESQUECI MINHA SENHA</p>
          </a>

        </mat-card-content>


      </mat-card>
    </div>
  </div>
</div>
