import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class ResizeService {
  constructor() {}
  public listenEvent: EventEmitter<any> = new EventEmitter();
  private _sizeNow: any = {
    width: 0,
    height: 0,
    page: '',
  };

  public listenElements() {
    let width: number;
    let height: number;
    let page: string;
    let toolbarSelect: any;
    let _size: any;

    setInterval(() => {
      let win = window,
        doc = document,
        docElem = doc.documentElement,
        body = doc.getElementsByTagName('body')[0];
      width = win.innerWidth || docElem.clientWidth || body.clientWidth;
      height = win.innerHeight || docElem.clientHeight || body.clientHeight;
      page = window.location.pathname.toString().split('/')[1];

      _size = {
        width: width,
        height: height,
        page: page,
      };

      toolbarSelect = this.toolbarShow(width, height, page);
      if (
        this._sizeNow.width !== _size.width ||
        this._sizeNow.height !== _size.height ||
        this._sizeNow.page !== _size.page
      ) {
        // console.log('--------------------resize--------------------');
        // console.log(page);
        this.listenEvent.emit({
          dimensions: {
            width: width,
            height: height,
          },
          page: page,
          toolbarSelect,
        });
        this._sizeNow = _size;
      }
    }, 100);
  }

  private toolbarShow(width, height, page) {
    let toolbarsShowFlag;
    if (width <= 401 && height <= 1080) {
      toolbarsShowFlag = {
        topToolbar: false,
        bottonToolbar: true,
      };

    }
    else if (width <= 575 && height <= 1080) {
      toolbarsShowFlag = {
        topToolbar: false,
        bottonToolbar: true,
      };

    }
    else if (width <= 768 && height <= 1080) {
      toolbarsShowFlag = {
        topToolbar: false,
        bottonToolbar: true,
      };
    }
    else if (width <= 1024 && height <= 1366) {
      toolbarsShowFlag = {
        topToolbar: true,
        bottonToolbar: false,
      };
    }
    else if (width <= 1440 && height <= 1080) {
      toolbarsShowFlag = {
        topToolbar: true,
        bottonToolbar: false,
      };
    }
    else if (width <= 2560 && height <= 1080) {
      toolbarsShowFlag = {
        topToolbar: true,
        bottonToolbar: false,
      };
    }
    else if (width >= 1080 && height >= 1080) {
      toolbarsShowFlag = {
        topToolbar: true,
        bottonToolbar: false,
      };
    }
    else {
      toolbarsShowFlag = {
        topToolbar: true,
        bottonToolbar: false,
      };
    };
    return toolbarsShowFlag;
  }
}
