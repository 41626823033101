import { Injectable } from '@angular/core';
import * as socketio from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { Subject, Observable } from 'rxjs';
import { Customer } from '../shared/models/customer.model';
@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  private socket = socketio(environment.processApi);
  private subjMessages: Subject<Customer[]> = new Subject<Customer[]>();

  constructor() {
    this.socket.on('customers', (customers: Customer[]) => {
      this.subjMessages.next(customers);
    });
  }

  customersFromSocketIo(): Observable<Customer[]> {
    return this.subjMessages.asObservable();
  }
}
