import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private menuDialog: any;
  constructor() { }

  ngOnInit(): void {
  }

  public openMenuDialog(): void {

    this.menuDialog = document.getElementsByClassName("cdk-overlay-pane")
    for (let item of this.menuDialog) {
      if(item.firstChild.id === 'mat-menu-panel-0'){
        item.id = "my-div-id"
      }
    }
  }
}
