import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Customer } from 'src/app/shared/models/customer.model';
import { CustomerService } from '../../shared/services/customer.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {
  customers: Customer[];
  isLoadingResults: boolean;
  displayedColumns = ['imageB64', 'id', 'createdAt', 'detectionScore', 'gender', 'age', 'bestExpression', 'totalVisitas', 'momentInformation'];
  form: FormGroup;

  constructor(
    private customerService: CustomerService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.customerService.get({
      startDate: '12-31-2019,23:59:34',
      endDate: '12-31-2020,23:59:28'
    }).subscribe(customers => {
      this.isLoadingResults = false;
      this.customers = customers;
    });

    this.buildForm();
  }

  private buildForm() {
    this.form = this.fb.group({
      startDate: [''],
      endDate: [''],
      gender: [''],
      age: [''],
      expression: ['']
    });
  }

  public processAll() {
    const button = document.getElementById('process-button');
    button.classList.toggle('sending');
    button.innerHTML = 'Atualizando...';
    button.setAttribute('style', 'background-color: #007fb8');
    this.customerService.patchProcess().subscribe(response => {
      if (response['result'] === 'success') {
        this.customerService.patchProcess_in_out().subscribe(() => {
          if (response['result'] === 'success') {
            button.setAttribute('style', 'background-color: rgb(67, 230, 110)');
            button.classList.remove('sending');
            button.innerHTML = 'Dados Atualizados';
            setTimeout(function () {
              button.setAttribute('style', 'background-color: #03A9F4');
              button.innerHTML = 'Atualizar Resultados';
            }, 2500);
          }
        });
      }
    });
  }

  submitForm() {
    this.isLoadingResults = true;
    const dataObj = this.form.value;

    if (dataObj.startDate === '' || dataObj.startDate === undefined) { dataObj['startDate'] = '12-31-2019,23:59:34' }
    if (dataObj.endDate === '' || dataObj.endDate === undefined) { dataObj['endDate'] = '12-31-2020,23:59:28' }
    if (dataObj.gender === '' || dataObj.gender === undefined) { delete dataObj['gender'] }
    if (dataObj.age === '' || dataObj.age === undefined) { delete dataObj['age'] }
    if (dataObj.expression === '' || dataObj.expression === undefined) { delete dataObj['expression'] }

    this.customerService.get(dataObj).subscribe(customers => {
      customers = customers.filter(this.reduceArray)
      this.isLoadingResults = false;
      this.customers = customers;
    });
  }

  reduceArray(customer: any) {
    return customer.momentInformation.length > 0;
  }
}
