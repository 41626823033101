import { Component, OnInit } from '@angular/core';
import { Store } from 'src/app/shared/models/store.model';
import { StoreService } from 'src/app/shared/services/store.service';

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.scss']
})
export class StoreListComponent implements OnInit {

  stores: Store[];
  displayedColumns = ['id', 'name', 'action'];
  dontHaveAStore: boolean;

  constructor(private storeService: StoreService) { }

  ngOnInit(): void {
    this.storeService.get().subscribe(stores => {
      this.stores = stores;
      if (this.stores.length === 0) { this.dontHaveAStore = true; }
    });
  }

}
