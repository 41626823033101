<div style="padding: 20px;">
    <div class="loading-shade" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>

    <app-webcam-warning-card *ngIf="devicesAccessIsBlocked" ></app-webcam-warning-card>

    <div>
        <mat-form-field class="example-full-width" appearance="outline">
        <input type="text"
                placeholder="Pesquisa de Loja"
                aria-label="Number"
                matInput
                [formControl]="storesCtrl"
                [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="loadSelectedStore($event.option.value)">
            <mat-option
                *ngFor="let store of filteredStores | async"
                [value]="store">
                {{ store.name }}
            </mat-option>
        </mat-autocomplete>
        </mat-form-field>
    </div>

    <section>
        <form [formGroup]="form" fxFlex fxLayout="column" (ngSubmit)="submitForm()">
            <div fxFlex fxLayoutGap="10px" formArrayName="cameras"> <!-- Container -->
                <mat-card *ngFor="let camera of cameras.controls; let i=index" fxFlex class="mat-elevation-z4">
                    <mat-card-header>
                        <div mat-card-avatar><i class="material-icons">camera_alt</i></div>
                        <mat-card-title>{{ camera.value.description }}</mat-card-title>
                        <mat-card-subtitle>{{ camera.value.type }}</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-form-field fxFlex>
                            <mat-label>Selecione uma câmera</mat-label>
                            <mat-select [formControl]="camera.get('deviceId')">
                            <mat-option *ngFor="let webcam of webcams" [value]="webcam.deviceId">
                                {{ webcam.label }} - {{ webcam.deviceId }}
                            </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-card-content>
                </mat-card>
            </div>
            <div style="padding-top: 20px;">
                <button mat-raised-button #btnSubmit type="submit" color="primary">Salvar</button>
            </div>
        </form>
    </section>
</div>
