<div style="padding: 20px;">
    <mat-card class="mat-elevation-z4">
        <div class="loading-shade" *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>
        <mat-card-title>{{ pageTitle }}</mat-card-title>
        <form [formGroup]="form" (ngSubmit)="submitForm()">
            <mat-form-field>
                <input matInput formControlName="name" maxlength="30" placeholder="Nome" name="name" required>
                <mat-error *ngIf="form.get('name').hasError('required')">Campo obrigatório</mat-error>
                <mat-hint align="end">{{form.get('name').value?.length || 0}}/30</mat-hint>
            </mat-form-field>

            <div fxFlex fxLayout="Column">
                <h3> Câmeras
                    <button type="button" #btnAddCam mat-icon-button color="primary" (click)="addCamera()">
                        <i class="material-icons">add_circle</i>
                    </button>
                </h3>

                <div class="cameras-container" formArrayName="cameras" fxFlex fxLayoutGap="10px">
                    <mat-card class="camera-card" *ngFor="let camera of cameras.controls; let i=index">
                        <mat-form-field>
                            <mat-label>Descrição</mat-label>
                            <input matInput name="description" [formControl]="camera.get('description')" maxlength="200" required>
                            <mat-error *ngIf="camera.get('description').hasError('required')">Campo obrigatório</mat-error>
                            <mat-hint align="end">{{camera.get('description').value?.length || 0}}/200</mat-hint>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Tipo</mat-label>
                            <mat-select [formControl]="camera.get('type')" required>
                              <mat-option *ngFor="let type of types" [value]="type">
                                {{ type }}
                              </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-card-actions>
                            <button mat-button (click)="removeCamera(i)" [disabled]="currentAction=='delete'">REMOVER</button>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </div>

            <div>
                <button mat-raised-button #btnSubmit type="submit" color="primary" [disabled]="form.invalid">Salvar</button>

                <button type="button" routerLink="/stores" mat-raised-button>
                    Cancelar
                </button>
            </div>
        </form>
    </mat-card>
</div>
