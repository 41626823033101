import { Heatmap } from './../models/heatmap.model';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeatmapService {  

  baseUrl = environment.restApiHeatmap;

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient) {}


  // Get company by id
  getCompanyById(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/api/Company/' + id);
  }

  // Get spaces from company ID
  getSpaces(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/api/Space/' + id);
  }

  // Get peripherals (eg: cameras) by space ID
  getPeripheralCompanies(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/api/PeripheralCompany/' + id);
  }

  postGetGraph(heatmap: Heatmap): Observable<any[]> {
    return this.http.post<any>(this.baseUrl + '/api/Heatmap/GetGraph/', heatmap);
  }

  postGetMap(heatmap: Heatmap) {
    return this.http.post<any>(this.baseUrl + '/api/Heatmap/GetMap/', heatmap);
  }

}
