<mat-card (click)="openAllowWebcamDialog()" class="mat-elevation-z4 webcam-warning">
    <mat-card-header>
        <div mat-card-avatar>
            <i class="material-icons">warning</i>
        </div>
        <mat-card-title class="title">
            Sem permissão para acessar a webcam!
        </mat-card-title>
        <mat-card-subtitle class="subtitle">
            Para saber como permitir o acesso, clique neste card.
        </mat-card-subtitle>
    </mat-card-header>
</mat-card>