<h1 mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" color="primary">
    Permitindo o acesso a webcam
    <button class="mat-icon-button" (click)="closeDialog()">
        <i class="material-icons"> close </i>
    </button>
</h1>
<div mat-dialog-content>
    <ol>
        <li>
            <p>Navegue até `chrome://flags/#unsafely-treat-insecure-origin-as-secure`.</p>
        </li>
        <li>
            <p>Em `Insecure origins treated as secure`, adicionar a url de teste ou dev:</p>
            <img class="logo"
                src="../assets/img/allow-webcam-access/image-01.png"
                style="width: 100%;"
                alt="teste">
        </li>
        <li>
            <p>Na parte inferior direita clicar em `Relaunch`:</p>
            <img class="logo"
                src="../assets/img/allow-webcam-access/image-02.png"
                style="max-width: 200px;"
                alt="teste">
        </li>
        <li>
            <p>Na página do Customer Profile, após recarregá-la, clicar no ícone de exclamação
                presente ao lado esquerdo da URL e em `Configurações do site`, permitir o acesso a câmera.</p>
            <img class="logo"
                src="../assets/img/allow-webcam-access/image-03.png"
                style="width: 100%;"
                alt="teste">
        </li>
        <li>
            <p>Recarregue a página.</p>
        </li>
    </ol>
</div>