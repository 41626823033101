import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AllowWebcamAccessDialogComponent } from '../allow-webcam-access-dialog/allow-webcam-access-dialog.component';

@Component({
  selector: 'app-webcam-warning-card',
  templateUrl: './webcam-warning-card.component.html',
  styleUrls: ['./webcam-warning-card.component.scss']
})
export class WebcamWarningCardComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openAllowWebcamDialog(): void {
    const dialogRef = this.dialog.open(AllowWebcamAccessDialogComponent, {
      maxWidth: '50vw',
      panelClass: 'no-lateral-padding'
    });
  }

}
