import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Customer } from 'src/app/shared/models/customer.model';
import { CustomerService } from 'src/app/shared/services/customer.service';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {
  public dataSource: Customer[];
  public isLoadingResults: boolean;
  public displayedColumns = ['imageB64', 'id', 'createdAt', 'detectionScore', 'gender', 'age', 'bestExpression'];

  constructor(private route: ActivatedRoute, private customerService: CustomerService) { }

  ngOnInit(): void {
    this.isLoadingResults = true;
    const customerId = this.route.snapshot.params['id'];

    this.customerService.getDetail(customerId)
      .subscribe(customers => {
        this.isLoadingResults = false;
        this.dataSource = customers;
      });

  }

}
