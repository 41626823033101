import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from './user.model';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  user: User;
 
  constructor(private http: HttpClient, private router: Router) { }

  isLoggedIn(): boolean {
    return this.user !== undefined;
  }

  // login(email: string, password: string): Observable<User> {
  //   return this.http.post<User>(`${environment.restApi}/login`, { email: email, password: password })
  //     .pipe(
  //       tap(user => this.user = user)
  //     )
  // }

  login() {
    this.user = {name: 'user', email: 'user@email', accessToken: '123'};
  }

  handleLogin(path: string) {
    this.router.navigate(['/login', path]);
  }
}
