<div style="padding: 20px;">
  <table class="mat-elevation-z4" mat-table [dataSource]="stores">
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let row; let i=index">{{i + 1}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let row">{{row.name}}</td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Ações</th>
      <td mat-cell *matCellDef="let row; let i=index">
        <a routerLink="/stores/edit/{{ row._id }}" class="edit">
          <i class="material-icons">
              edit
          </i>
        </a>
        <a *ngIf="false" routerLink="/stores/delete/{{ row._id }}" class="delete">
            <i class="material-icons">
                delete
            </i>
        </a>
        <!-- <a *ngIf="i !== 0" routerLink="/stores/delete/{{ row._id }}" class="delete">
          <i class="material-icons">
              delete
          </i>
      </a> -->
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <p class="empty-row-table" *ngIf="dontHaveAStore">Nenhuma loja cadastrada</p>
</div>
