import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';
import { CustomerService } from '../shared/services/customer.service';
import { DashBoardFilterService } from '../shared/services/dashboard-filter.service';
import { Subscription } from 'rxjs';
import { SocketioService } from '../monitoring/socketio.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public isLoadingResults: boolean;
  private wsSubscription: Subscription;
  private legend: any;


  // CHART
  public resize() {
    const mq = window.matchMedia("(max-width: 768px)");
    const mw = window.matchMedia("(min-width: 1070px) and (max-width: 1200px) and (min-height: 1500px)");
    setInterval(() => {
      if (mq.matches) {
        this.barChartOptions.legend = {
          position: 'top',
          labels: {
            fontSize: 12,
            boxWidth: 15,
          }
        }
        this.barChartOptions.scales.xAxes[0].ticks.fontSize = 11.5;
        this.barChartOptions.scales.yAxes[0].ticks.fontSize = 11.5;
        this.pieChartOptions.legend = {
          position: 'top',
          labels: {
            fontSize: 12,
            boxWidth: 15,
          }
        }
        this.barChartOptions.aspectRatio = 1.2;
        this.pieChartOptions.aspectRatio = 1.5;
      }
      else {
        this.barChartOptions.aspectRatio = 2;
        this.pieChartOptions.aspectRatio = 2;
        this.barChartOptions.legend = { position: 'right' }
        this.pieChartOptions.legend = { position: 'right' }
      }
      if (mw.matches) {
        this.barChartOptions.aspectRatio = 1.6;
        this.pieChartOptions.aspectRatio = 1.4;
        this.barChartOptions.scales.xAxes[0].ticks.fontSize = 14;
        this.barChartOptions.scales.yAxes[0].ticks.fontSize = 14;
        this.barChartOptions.legend = {
          position: 'right',
          labels: {
            padding: 25,
            fontSize: 14,
            boxWidth: 50,
          }
        }
        this.pieChartOptions.legend = {
          position: 'right',
          labels: {
            padding: 25,
            fontSize: 14,
            boxWidth: 50,
          }
        }
      }
    }, 250);
  }

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          fontSize: 14,
          padding: 0,
          fontColor: '#545454'
        }
      }],
      yAxes: [{
        ticks: {
          fontSize: 14,
          padding: 0,
          fontColor: '#545454',
          beginAtZero: true
        }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        display: false
      },
    },
    legend: this.legend,
  };
  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
    },
    plugins: {
      datalabels: {
        display: false
      },
    }
  };
  public totalMiddleTime = 0;
  public totalCounter = 0
  public pieChartLabels: Label[] = [];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['#0290B0', '#02B027', '#1038FC',
        '#03ADFC', '#000000', '#ebd534', '#567800'],
      defaultFontColor: '#fff',
    },
  ];
  public barChartColors = [
    { backgroundColor: '#0290B0' }, { backgroundColor: '#02B027' }, { backgroundColor: '#1038FC' }
  ];

  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartPlugins = [pluginDataLabels];

  public barChartLabels: Label[] = [];

  public permanenceChartLabels: Label[] = [];

  public genderChartLabels: Label[] = [];

  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Masculino' },
    { data: [], label: 'Feminino' },
    { data: [], label: 'Total' }
  ];

  public genderChartData: ChartDataSets[] = [
    { data: [], label: 'Masculino' },
    { data: [], label: 'Feminino' },
    { data: [], label: 'Total' }
  ];

  public permanenceChartData: ChartDataSets[] = [
    { data: [], label: 'Masculino' },
    { data: [], label: 'Feminino' },
    { data: [], label: 'Média' },
  ];

  constructor(
    private customerService: CustomerService,
    private filterService: DashBoardFilterService,
    private socketService: SocketioService) { }

  ngOnInit(): void {
    this.resize();
    this.isLoadingResults = true;
    this.filterService.currentFilter.subscribe(_ => this.loadChartsData());
    this.subscribeToWebSocket();
  }

  ngOnDestroy(): void {
    this.wsSubscription.unsubscribe();
  }

  // PRIVATE METHODS
  private loadChartsData(update = false) {
    this.isLoadingResults = true;

    this.clearChartsData(update);

    this.customerService.getMidTime().subscribe(resp => this.setPermanenceChartData(resp, update));
    this.customerService.getExpressionChartData().subscribe(resp => this.setPieChartData(resp, update));
    this.customerService.getGenderChartData().subscribe(resp =>
    this.setGenderChartData(resp.map(item => this.normalizeAgeRange(item)), update));
    this.customerService.getNumberOfPeoples().subscribe(resp => this.setPeopleChartData(resp, update));

    this.isLoadingResults = false;
  }

  private setGenderChartData(data, update) {
    const { gender } = this.filterService.dashBoardFilter;
    const chartData: ChartDataSets[] = [{ data: [], label: 'Masculino' }, { data: [], label: 'Feminino' }, { data: [], label: 'Total' }];

    switch (gender) {
      case 'male':
        this.genderChartData = this.genderChartData.filter(item => item.label === 'Masculino');
        break;
      case 'female':
        this.genderChartData = this.genderChartData.filter(item => item.label === 'Feminino');
        break;
    }

    data.forEach((item: any) => {
      if (this.allFilterIsSelected() || this.filterService.dashBoardFilter.ageRange[item.age_range]) {
        if (!update) {
          this.genderChartLabels.push(item.age_range);
        }
        switch (gender) {
          case 'male':
            chartData[0].data.push(item.gender[0].male);
            break;
          case 'female':
            chartData[0].data.push(item.gender[1].female);
            break;
          default:
            chartData[0].data.push(item.gender[0].male);
            chartData[1].data.push(item.gender[1].female);
            chartData[2].data.push(item.gender[2].total);
        }
      }
    });
    if (gender === 'all') {
      this.genderChartData[0].data = chartData[0].data;
      this.genderChartData[1].data = chartData[1].data;
      this.genderChartData[2].data = chartData[2].data;
    } else {
      this.genderChartData[0].data = chartData[0].data;
    }
  }

  private setPeopleChartData(data, update) {
    const { gender } = this.filterService.dashBoardFilter;
    const chartData: ChartDataSets[] = [{ data: [], label: 'Masculino' }, { data: [], label: 'Feminino' }, { data: [], label: 'Total' }];

    // if (!update) {
    //   this.barChartLabels.push(`Início:${data.date.start.split('T')[0]} | Fim:${data.date.end.split('T')[0]}`);
    // }
    switch (gender) {
      case 'male':
        this.barChartData = this.barChartData.filter(item => item.label === 'Masculino');
        break;
      case 'female':
        this.barChartData = this.barChartData.filter(item => item.label === 'Feminino');
        break;
    }

    switch (gender) {
      case 'male':
        chartData[0].data.push(data.male);
        break;
      case 'female':
        chartData[0].data.push(data.female);
        break;
      default:
        chartData[0].data.push(data.male);
        chartData[1].data.push(data.female);
        chartData[2].data.push(data.middle);
    }
    if (gender === 'all') {
      this.barChartData[0].data = chartData[0].data;
      this.barChartData[1].data = chartData[1].data;
      this.barChartData[2].data = chartData[2].data;
    } else {
      this.barChartData[0].data = chartData[0].data;
    }
  }

  private setPermanenceChartData(data, update) {
    const { gender } = this.filterService.dashBoardFilter;
    const chartData: ChartDataSets[] = [{ data: [], label: 'Masculino' }, { data: [], label: 'Feminino' }, { data: [], label: 'Média' }];

    switch (gender) {
      case 'male':
        this.permanenceChartData = this.permanenceChartData.filter(item => item.label === 'Masculino');
        break;
      case 'female':
        this.permanenceChartData = this.permanenceChartData.filter(item => item.label === 'Feminino');
        break;
    }

    data.forEach((item: any) => {
      if (this.allFilterIsSelected() || this.filterService.dashBoardFilter.ageRange[`${item.AgeRange.min}-${item.AgeRange.max}`]) {
        if (!update) {
          this.permanenceChartLabels.push(`${item.AgeRange.min} - ${item.AgeRange.max}`);
        }
        switch (gender) {
          case 'male':
            chartData[0].data.push(item.values.male);
            break;
          case 'female':
            chartData[0].data.push(item.values.female);
            break;
          default:
            chartData[0].data.push(item.values.male);
            chartData[1].data.push(item.values.female);
            chartData[2].data.push(item.values.averageTime);
        }
      }
    });
    if (gender === 'all') {
      this.permanenceChartData[0].data = chartData[0].data;
      this.permanenceChartData[1].data = chartData[1].data;
      this.permanenceChartData[2].data = chartData[2].data;
    } else {
      this.permanenceChartData[0].data = chartData[0].data;
    }
    this.totalMiddleTime = 0;
    this.totalCounter = 0;
    chartData[2].data.forEach(number => {
      if(number > 0){
        this.totalMiddleTime += number
        this.totalCounter++
      }
    });
    this.totalMiddleTime = (this.totalMiddleTime / this.totalCounter);

  }

  private setPieChartData(data, update) {
    const chartData = [];
    data.forEach((item: any) => {
      if (this.allFilterIsSelected() || this.filterService.dashBoardFilter.expressions[item.expression]) {
        if (!update) { this.pieChartLabels.push(item.sentimento); }
        chartData.push(item.total);
      }
    });
    this.pieChartData = chartData;
  }

  private clearChartsData(update) {
    if (!update) {
      this.barChartData = [
        { data: [], label: 'Masculino' },
        { data: [], label: 'Feminino' },
        { data: [], label: 'Total' }
      ];

      this.genderChartData = [
        { data: [], label: 'Masculino' },
        { data: [], label: 'Feminino' },
        { data: [], label: 'Total' }
      ];

      this.permanenceChartData = [
        { data: [], label: 'Masculino' },
        { data: [], label: 'Feminino' },
        { data: [], label: 'Média' }
      ];

      this.pieChartData = [];
      this.barChartLabels = [];
      this.genderChartLabels = [];
      this.permanenceChartLabels = [];
      this.pieChartLabels = [];
    }
  }

  private normalizeAgeRange(item) {
    switch (item.age_range) {
      case '11-20':
        return { age_range: '10-20', gender: item.gender };
      case '21-30':
        return { age_range: '20-30', gender: item.gender };
      case '31-40':
        return { age_range: '30-40', gender: item.gender };
      case '41-50':
        return { age_range: '40-50', gender: item.gender };
      case '51-60':
        return { age_range: '50-60', gender: item.gender };
      default:
        return item;
    }
  }

  private subscribeToWebSocket() {
    this.wsSubscription = this.socketService.customersFromSocketIo().subscribe(
      res => this.loadChartsData(true),
      err => console.log(err)
    );
  }

  private allFilterIsSelected() {
    const selectedQuantity = Object.values({
      ...this.filterService.dashBoardFilter.expressions,
      ...this.filterService.dashBoardFilter.ageRange
    }).filter(value => value === true).length;
    return selectedQuantity === 0;
  }
}
