// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  restApi: 'https://742948a04d1f.ngrok.io',//'http://172.100.10.101:8121', // 'http://localhost:3000'
  processApi: 'http://172.100.10.101:8122',//'http://172.100.10.101:8122',
  restApiHeatmap: 'http://172.100.11.125:2424'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
