<div style="padding: 20px;">
  <form [formGroup]="form" (ngSubmit)="submitForm()" class="form-layout">
    <mat-form-field>
      <mat-label>Data início</mat-label>
      <input matInput [ngxMatDatetimePicker]="picker" placeholder="Data início" formControlName="startDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #picker>
      </ngx-mat-datetime-picker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Data fim</mat-label>
      <input matInput [ngxMatDatetimePicker]="picker2" placeholder="Data fim" formControlName="endDate">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #picker2>
      </ngx-mat-datetime-picker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Sexo</mat-label>
      <mat-select formControlName="gender">
        <mat-option>Todos</mat-option>
        <mat-option value="male">Masculino</mat-option>
        <mat-option value="female">Feminino</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Faixa etária</mat-label>
      <mat-select formControlName="age">
        <mat-option>Todas</mat-option>
        <mat-option value="1,15">1 a 15 anos</mat-option>
        <mat-option value="16,25">16 a 25 anos</mat-option>
        <mat-option value="26,35">26 a 35 anos</mat-option>
        <mat-option value="36,45">36 a 45 anos</mat-option>
        <mat-option value="46,55">46 a 55 anos</mat-option>
        <mat-option value="56,65">56 a 65 anos</mat-option>
        <mat-option value="66,200">mais que 66 anos</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Sentimento</mat-label>
      <mat-select formControlName="expression">
        <mat-option>Todos</mat-option>
        <mat-option value="surprised">surpreso</mat-option>
        <mat-option value="disgusted">enjoado</mat-option>
        <mat-option value="fearful">medo</mat-option>
        <mat-option value="angry">bravo</mat-option>
        <mat-option value="sad">triste</mat-option>
        <mat-option value="happy">feliz</mat-option>
        <mat-option value="neutral">neutro</mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-raised-button #btnSubmit type="submit" color="primary" class="search-button">Pesquisar</button>
  </form>

  <div class="mat-elevation-z4">
    <div class="table-container">
      <div *ngIf="isLoadingResults" class="loading-shade">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
      </div>
      <table mat-table [dataSource]="customers">
        <!-- Image -->
        <ng-container matColumnDef="imageB64">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <img mat-card-image style="width:50px; height: auto; border-radius: 50%; margin: 0" src="{{row.imageB64}}"
              alt="...">
          </td>
        </ng-container>

        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let row">{{row._id}}</td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>Data</th>
          <td mat-cell *matCellDef="let row">{{row.createdAt | date:'dd/MM/yyyy'}}</td>
        </ng-container>

        <!-- Detection Score Column -->
        <ng-container matColumnDef="detectionScore">
          <th mat-header-cell *matHeaderCellDef>Pontuação da detecção</th>
          <td mat-cell *matCellDef="let row">{{row.alignedRect._score | number:'1.3-3'}}</td>
        </ng-container>

        <!-- Gender Column -->
        <ng-container matColumnDef="gender">
          <th mat-header-cell *matHeaderCellDef>Gênero</th>
          <td mat-cell *matCellDef="let row">{{ row.gender | translate}}</td>
        </ng-container>

        <!-- Age Column -->
        <ng-container matColumnDef="age">
          <th mat-header-cell *matHeaderCellDef>Idade</th>
          <td mat-cell *matCellDef="let row">{{row.age}}</td>
        </ng-container>

        <!-- bestExpression Column -->
        <ng-container matColumnDef="bestExpression">
          <th mat-header-cell *matHeaderCellDef>Expressão</th>
          <td mat-cell *matCellDef="let row">{{row.bestExpression?.best | translate}}</td>
        </ng-container>

        <!-- momentInformantion Column -->
        <ng-container matColumnDef="totalVisitas">
          <th mat-header-cell *matHeaderCellDef>Total visitas</th>
          <td mat-cell *matCellDef="let row">{{row.momentInformation?.length}}</td>
        </ng-container>

        <!-- momentInformantion Column -->
        <ng-container matColumnDef="momentInformation">
          <th mat-header-cell *matHeaderCellDef>Entrada(s) e Saída(s)</th>
          <td mat-cell *matCellDef="let row">
            <li *ngFor="let inOut of row.momentInformation">
              {{inOut.in | date:'dd/MM/yyyy HH:mm'}} - {{inOut.out | date:'dd/MM/yyyy HH:mm'}}
            </li>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['detail', row._id]"></tr>
      </table>
    </div>
  </div>
</div>
