import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { Store } from '../models/store.model';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  private _selectedStore = new BehaviorSubject<Store>({
    cameras: [],
    name: '',
    _id: undefined
  });

  private _currentSelectedStore = this._selectedStore.asObservable();

  baseUrl = environment.restApi + '/stores';

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient) {
      if (!this.selectedStore._id) {
        this.get().subscribe(stores => {
          if (stores.length) {
            this.setStore(stores[0]);
          }
        });
      }
    }

  showMessage(msg: string): void {
    this.snackBar.open(msg, 'X', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  create(store: Store): Observable<Store> {
    return this.http.post<Store>(this.baseUrl, store);
  }

  update(store: Store, id: number): Observable<Store> {
    return this.http.put<Store>(this.baseUrl + '/' + id, store);
  }

  get(): Observable<Store[]> {
    return this.http.get<Store[]>(this.baseUrl);
  }

  getById(id: string): Observable<Store> {
    return this.http.get<Store>(this.baseUrl + '/' + id);
  }

  findOne(): Observable<Store> {
    return this.http.get<Store>(this.baseUrl + '/find-one');
  }

  delete(id: string): Observable<any> {
    return this.http.delete<any>(this.baseUrl + '/' + id);
  }

  get selectedStore() {
    return this._selectedStore.value;
  }

  set selectedStore(store: Store) {
    this.setStore(store);
    this._selectedStore.next(store);
  }

  get currentSelectedStore() {
    return this._currentSelectedStore;
  }

  private setStore(store: Store) {
    this._selectedStore.value._id = store._id;
    this._selectedStore.value.cameras = store.cameras;
    this._selectedStore.value.name = store.name;
  }

}
