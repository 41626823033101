import { HeatmapFilterService } from './../shared/services/heatmap-filter.service';
import { Heatmap } from './../shared/models/heatmap.model';
import { StoreService } from 'src/app/shared/services/store.service';
import { HeatmapService } from './../shared/services/heatmap.service';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Color } from 'ng2-charts';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { resolve } from 'dns';
import { rejects } from 'assert';

interface ImageChart {
  spaceName: string;
  charts: string;
  images: string;
  lineChartData: any;
  lineChartLabels: any;
}

@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.css']
})
export class HeatmapComponent implements OnInit {

  public barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    vAxis: { minValue: 0 },
    title: {
      text: 'Tráfego de pessoas',
      display: true,
      fontSize: 20,
      horizontalAlign: 'left'
    },
    legend: {
      position: 'right'
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  };

  // public barChartLabels = ['2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019'];
  public barChartLabels = ['teste1', 'teste2'];
  public barChartType = 'line';
  public barChartLegend = true;
  public barChartData = [];
  //   {data: [65, 59, 80, 81, 56, 55, 40, 25, 26, 77, 44, 21, 33, 45], label: 'Series A'},
  //   {data: [28, 48, 40, 19, 86, 27, 90, 20, 50, 48, 36, 78, 55, 89], label: 'Series B'}
  // ];


  public lineChartColors: Color[] = [
    {
      borderColor: '#02B027',
      backgroundColor: '#02B0271A',
    },
    {
      borderColor: '#1038FC',
      backgroundColor: '#0F36F11A',
    },

  ];

  public lineChartPlugins = [];



  public imageChart: ImageChart[] = [];


  heatmapForm: FormGroup;
  showChart = false;
  showChart2 = false;

  idCamera: number;
  idCompany: number;





  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 50;
  isLoading = false;


  variable: Date;

  dataInicio: Date;
  dataFim: Date;

  // tslint:disable-next-line: new-parens
  filterMap: Heatmap = new class implements Heatmap {
    peripheralCompanyId: number;
    dataInicio: Date;
    dataTermino: Date;
  };

  ambientes: any[];
  cameras: any[];

  constructor(
    private heatmapService: HeatmapService,
    private storeService: StoreService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private heatmapFilterService: HeatmapFilterService
    // private dialogRef: MatDialogRef<FilterDateDialogComponent>
  ) { }

  ngOnInit(): void {
    // this.heatmapService.getCompanyById(1).subscribe(res => {
    //   // console.log(res);
    // });


    this.heatmapFilterService.currentFilter.subscribe(res => {
      this.imageChart = [];
      if (res.initialDate !== '' && res.finalDate !== '') {
        this.filterMap.dataInicio = res.initialDate;
        this.filterMap.dataTermino = res.finalDate;

        this.generateHeatmap(this.filterMap);
      }
    });

  }




  // private loadCompanies() {
  //   this.companyService.apiCompanyGet().subscribe(companies => {
  //     this.companies = companies;
  //   });
  // }



  formatDate(date: Date): string {
    const formattedDate = new Date(date);
    return formattedDate.getDate() + '/' + (formattedDate.getMonth() + 1) + '/' + formattedDate.getFullYear();
  }

  companySelected(event) {
    this.idCompany = event.value;
  }



  async generateHeatmap(filtro: Heatmap) {
    this.isLoading = true;

    await this.heatmap2(filtro);

    this.isLoading = false;

  }

  async heatmap2(filtro: Heatmap) {
    this.cameras = [];
    this.ambientes = [];


    await new Promise((resolve, reject) => {
      this.heatmapService.getSpaces(1).subscribe( res => {
        this.ambientes = res;
        resolve();
      });
    });
    console.log(this.ambientes);

    for (const space of this.ambientes ) {
      await new Promise((resolve, reject) => {
        this.heatmapService.getPeripheralCompanies(space.id).subscribe( res => {
          const cameras = res;
          for (const camera of cameras) {
            this.cameras.push(camera);
          }
          resolve();
        });
      });
    }
    console.log(this.cameras);

    for (const camera of this.cameras) {
      filtro.peripheralCompanyId = camera.id;
      let dataChart;
      let dataMap;

      await new Promise((resolve, reject) => {
        this.heatmapService.postGetGraph(filtro).subscribe(res => {
          dataChart = res;
          resolve();
        });
      });

      await new Promise((resolve, reject) => {
        this.heatmapService.postGetMap(filtro).subscribe(res => {

          if (res === null) {
            dataMap = '';
          } else {
            dataMap = 'data:image/jpeg;base64,' + res.image;
          }
          resolve();
        });
      });

      this.buildArrayImageChart(dataChart, dataMap, camera.spaceCompany.spaceName);
    }
  }

  // async heatmap(filtro: Heatmap) {


  //     const getSpaces = () => {
  //       this.heatmapService.getSpaces(1).subscribe((spaces: Promise<any>) => spaces);
  //     };

  //     const getCameras = (spaceId: number) => {
  //       this.heatmapService.getPeripheralCompanies(spaceId).subscribe(cameras => cameras);
  //     };

  //     const getGraph = (filtro: Heatmap) => {
  //       this.heatmapService.postGetGraph(filtro).subscribe(chart => chart);
  //     };

  //     const getMap = (filtro: Heatmap) => {
  //       this.heatmapService.postGetMap(filtro).subscribe(map => map);
  //     };

  //     const spaceList = await new Promise( (resolve, reject) => );



  //     this.heatmapService.getSpaces(1).subscribe(ambientes => {
  //       this.ambientes = ambientes;

  //       this.ambientes.forEach(ambiente => {

  //         this.heatmapService.getPeripheralCompanies(ambiente.id).subscribe(cameras => {
  //           this.cameras = cameras;
  //           const totalCameras = this.cameras.length;

  //           let conn = 0;
  //           for (const camera of this.cameras) {
  //             filtro.peripheralCompanyId = camera.id;

  //             this.heatmapService.postGetGraph(filtro).subscribe(result => {

  //               if (result === null) {
  //                 result = [];
  //               }

  //               this.heatmapService.postGetMap(filtro).subscribe(res => {
  //                 let imageBackground;

  //                 if (res === null) {
  //                   imageBackground = '';
  //                 } else {
  //                   imageBackground = 'data:image/jpeg;base64,' + res.image;
  //                 }

  //                 this.buildArrayImageChart(result, imageBackground, camera.spaceCompany.spaceName);
  //                 conn++;
  //                 if (conn === totalCameras) {
  //                   resolve();
  //                 }


  //               }, err => {
  //                 this.imageChart.push({
  //                   spaceName: camera.spaceCompany.spaceName,
  //                   charts: 'no chart',
  //                   images: 'no image',
  //                   lineChartData: [],
  //                   lineChartLabels: []
  //                 });
  //               });
  //             }, err => {
  //               this.snackBar.open('Gráfico indisponível', null, {
  //                 duration: 3000
  //               });
  //             });
  //           }
  //         });
  //       }, err => {
  //         this.snackBar.open('Ambiente indisponível', null, {
  //           duration: 3000
  //         });
  //       });
  //     });


  // }

  transpose(matrix) {
    return Object.keys(matrix[0])
      .map(colNumber => matrix.map(rowNumber => rowNumber[colNumber]));
  }

  buildArrayImageChart(dataChart, imageBackgound, spaceName) {

    // if (dataChart.length === 0 || imageBackgound === '') {
    //   this.imageChart.push({
    //     spaceName,
    //     charts: '',
    //     images: '',
    //     lineChartLabels: [],
    //     lineChartData: [
    //       // { data: [], label: 'Média' },
    //       { data: [], label: 'Pico' }
    //     ]
    //   });
    // } else {
    //   const tableResponse = this.transpose(dataChart);

    //   this.imageChart.push({
    //     spaceName,
    //     charts: dataChart,
    //     images: imageBackgound,
    //     lineChartLabels: this.formatHour(tableResponse[2]),
    //     lineChartData: [
    //       // { data: tableResponse[3], label: 'Média' },s
    //       { data: tableResponse[1], label: 'Pico' }
    //     ]
    //   });
    // }

    if (dataChart.length !== 0 || imageBackgound !== '') {
      const tableResponse = this.transpose(dataChart);

      this.imageChart.push({
        spaceName,
        charts: dataChart,
        images: imageBackgound,
        lineChartLabels: this.formatHour(tableResponse[2]),
        lineChartData: [
          // { data: tableResponse[3], label: 'Média' },s
          { data: tableResponse[1], label: 'Pico' }
        ]
      });
    }
  }

  formatHour(labels) {
    const hours = [];
    for (const label of labels) {
      hours.push(new Date(label).toLocaleString('pt-BR', { timeZone: 'America/Manaus' }).slice(11, 16) + '\n' +
                  label.substring(8, 10) +
                  '/' +
                  label.substring(5, 7));
    }

    return hours;
  }




  dateSelectedInitial(date) {
    return new Date(date.getFullYear() + '/'
      + (date.getMonth() + 1) + '/'
      + date.getDate() + ' '
      + '00:00:00');
  }

  dateSelectedFinal(date: Date) {
    return new Date(date.getFullYear() + '/'
      + (date.getMonth() + 1) + '/'
      + date.getDate() + ' '
      + '23:59:59');
  }


  filterClean() {
    this.imageChart = [];
    this.filterMap.dataInicio = undefined;
    this.filterMap.dataTermino = undefined;

  }
}
