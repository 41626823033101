import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DashBoardFilterService } from '../shared/services/dashboard-filter.service';
import { MatDialogRef } from '@angular/material/dialog';
import { observable } from 'rxjs';

@Component({
  selector: 'app-dashboard-filter-dialog',
  templateUrl: './dashboard-filter-dialog.component.html',
  styleUrls: ['./dashboard-filter-dialog.component.scss']
})
export class DashboardFilterDialogComponent implements OnInit {
  public form: FormGroup;
  public myClass;
  public myId;

  constructor(
    private fb: FormBuilder,
    private filterService: DashBoardFilterService,
    public dialogRef: MatDialogRef<DashboardFilterDialogComponent>) { }

  ngOnInit(): void {
    this.buildForm();
    // this.resizeOverlay(window.innerWidth);
  }

  public filter(): void {
    if (this.form.valid) {
      this.formatControlsDate();
      this.filterService.dashBoardFilter = this.form.value;
      this.dialogRef.close();
    }
  }

  // PRIVATE METHODS
  private resizeOverlay(size: number) {
    // this.myClass[0].id = "my-class-id";
    // this.myId = document.getElementById("my-class-id")
    
    // if (size <= 425) {
    //   this.myId.style = "width:100%;height:100vh;margin-top:100px;";
    // }
    // else{
    //   this.myId.style = "";
    // }
  }

  private buildForm() {
    this.form = this.fb.group({
      initialDate: [this.filterService.dashBoardFilter.initialDate],
      finalDate: [this.filterService.dashBoardFilter.finalDate],
      gender: [this.filterService.dashBoardFilter.gender],
      expressions: this.fb.group({
        neutral: [this.filterService.dashBoardFilter.expressions.neutral],
        happy: [this.filterService.dashBoardFilter.expressions.happy],
        sad: [this.filterService.dashBoardFilter.expressions.sad],
        angry: [this.filterService.dashBoardFilter.expressions.angry],
        fearful: [this.filterService.dashBoardFilter.expressions.fearful],
        disgusted: [this.filterService.dashBoardFilter.expressions.disgusted],
        surprised: [this.filterService.dashBoardFilter.expressions.surprised]
      }),
      ageRange: this.fb.group({
        '0-10': [this.filterService.dashBoardFilter.ageRange['0-10']],
        '10-20': [this.filterService.dashBoardFilter.ageRange['10-20']],
        '20-30': [this.filterService.dashBoardFilter.ageRange['20-30']],
        '30-40': [this.filterService.dashBoardFilter.ageRange['30-40']],
        '40-50': [this.filterService.dashBoardFilter.ageRange['40-50']],
        '50-60': [this.filterService.dashBoardFilter.ageRange['50-60']],
      })
    });
  }

  private formatDate(date: Date) {
    if (date.toISOString) {
      return date.toISOString().split('T')[0];
    }
    return date;
  }

  private formatControlsDate() {
    this.form.value.initialDate = this.formatDate(this.form.value.initialDate);
    this.form.value.finalDate = this.formatDate(this.form.value.finalDate);
  }
}
