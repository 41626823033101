<div *ngIf="isLoadingResults" fxLayout="row" fxLayoutAlign="center center" class="loading-shade">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>

<div class="graphs">
  <div class="first-blocks">
    <div class="div-container" fxLayoutGap="24px">
      <mat-card id="media_time" class="mat-elevation-z4">
        <div style="display: flex;flex-direction: row; flex-wrap: wrap;" fxLayoutAlign="space-between center">
          <span mat-mat-card-title>Tempo de Permanência (minutos)</span>
          <span style="font-size: 0.9rem; color: darkgrey;">Tempo médio total:
            <strong style="color: lightslategrey;">{{ this.totalMiddleTime | number: "1.0-0" }}</strong>
            minuto(s)</span>
        </div>
        <canvas baseChart [datasets]="permanenceChartData" [labels]="permanenceChartLabels" [options]="barChartOptions"
          [plugins]="barChartPlugins" [legend]="barChartLegend" [colors]="barChartColors" [chartType]="barChartType">
        </canvas>
      </mat-card>
    </div>
    <div class="div-container">
      <mat-card id="media_people" class="middleOfPeopleCard mat-elevation-z4">
        <span mat-mat-card-title>Total de Pessoas por Período</span>
        <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
          [plugins]="barChartPlugins" [legend]="barChartLegend" [colors]="barChartColors" [chartType]="barChartType">
        </canvas>
      </mat-card>
    </div>
  </div>
  <div class="block-lasts">
    <div class="div-container">
      <mat-card id="media_expression" class="mat-elevation-z4">
        <span mat-mat-card-title>Humor</span>
        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
          [options]="pieChartOptions" [plugins]="pieChartPlugins" [colors]="pieChartColors" [legend]="pieChartLegend">
        </canvas>
      </mat-card>
    </div>
    <div class="div-container">
      <mat-card id="last_graph" class="mat-elevation-z4">
        <span mat-mat-card-title>Gênero por Idade</span>
        <canvas baseChart [datasets]="genderChartData" [labels]="genderChartLabels" [options]="barChartOptions"
          [plugins]="barChartPlugins" [legend]="barChartLegend" [colors]="barChartColors" [chartType]="barChartType">
        </canvas>
      </mat-card>
    </div>
  </div>
</div>
