import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashBoardFilterService {
  private _filterSource = new BehaviorSubject<any>(this.getFilterObject());

  private _currentFilter = this._filterSource.asObservable();

  constructor() {}

  get dashBoardFilter() {
    return this._filterSource.value;
  }

  set dashBoardFilter(filter) {
    this.setDashBoardFilter(filter);
    this._filterSource.next(filter);
  }

  get currentFilter() {
    return this._currentFilter;
  }

  resetFilter() {
    const filter = this.getFilterObject();
    this.dashBoardFilter = filter;
  }

  // PRIVATE METHODS

  private setDashBoardFilter(filter) {
    this._filterSource.value.initialDate = filter.initialDate;
    this._filterSource.value.finalDate = filter.finalDate;
    this._filterSource.value.gender = filter.gender;
    this._filterSource.value.expressions = filter.expressions;
  }

  private getFilterObject() {
   return {
      initialDate: '',
      finalDate: '',
      gender: 'all',
      expressions: {
        neutral: false,
        happy: false,
        sad: false,
        angry: false,
        fearful: false,
        disgusted: false,
        surprised: false
      },
      ageRange: {
        '0-10': false,
        '10-20': false,
        '20-30': false,
        '30-40': false,
        '40-50': false,
        '50-60': false,
      }
    };
  }
}
